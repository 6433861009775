import { useCallback } from 'react';
import {
	Box,
	FormHelperText,
	InputAdornment,
	Typography,
	TextField
} from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';

function EditInPlaceInput({
	dependingValues,
	disabled,
	error,
	helperText,
	label,
	labelSx,
	onBlur,
	onChange,
	required,
	subLabel,
	sx,
	type,
	value
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const onChangeHandler = useCallback((e) => {
		const incomingValue = e.target.value;

		if (incomingValue === '') {
			onChange('');
			return;
		}

		const incomingValueWithoutCommas = incomingValue.replaceAll(',', '');

		if (incomingValueWithoutCommas.includes('.')) {
			const indexOfPeriod = incomingValueWithoutCommas.indexOf('.');
			const numbersAfterthePeriod = incomingValueWithoutCommas.slice(
				indexOfPeriod + 1
			);
			const hasMoreThanTwoDecimals = numbersAfterthePeriod.length > 2;
			if (hasMoreThanTwoDecimals) return;
		}

		// eslint-disable-next-line no-restricted-globals
		if (isNaN(incomingValueWithoutCommas)) return;
		onChange(incomingValueWithoutCommas); // This is where the value changes
	}, dependingValues);

	return (
		<Box
			sx={{
				opacity: disabled ? 0.5 : 1,
				maxHeight: 100,
				...sx
			}}
		>
			{label && (
				<Typography
					variant="subtitle1"
					sx={{ marginBottom: 1, ...labelSx }}
				>
					{label}
				</Typography>
			)}

			<TextField
				disabled={disabled}
				variant="standard"
				type={type}
				required={required}
				InputProps={{
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								'&.MuiInputAdornment-standard': {
									marginRight: 0,
									'.MuiTypography-root.MuiTypography-body1': {
										color: '#29598380',
										fontSize: '1.75rem',
										marginRight: 0,
										fontWeight: 700
									}
								}
							}}
						>
							$
						</InputAdornment>
					)
				}}
				// helperText="Price Here"
				value={value}
				onChange={(e) => onChangeHandler(e)}
				onBlur={() => onBlur()}
				sx={{
					'& input[type=number]': {
						color: '#29598380',
						fontSize: '1.75rem',
						fontWeight: 700,
						MozAppearance: 'textfield'
					},
					'& input[type=number]::-webkit-outer-spin-button': {
						WebkitAppearance: 'none',
						margin: 0
					},
					'& input[type=number]::-webkit-inner-spin-button': {
						WebkitAppearance: 'none',
						margin: 0
					}
				}}
				FormHelperTextProps={{
					sx: { color: primary.black, textAlign: 'center' }
				}}
			/>
			{/* Below may be complex but if checked. There is an error. And we provided helper test display it! */}
			{!subLabel && error && helperText && (
				<FormHelperText
					variant="standard"
					sx={{
						marginLeft: 2
					}}
				>
					{helperText}
				</FormHelperText>
			)}
			{subLabel && (
				<FormHelperText
					variant="standard"
					sx={{
						marginLeft: 2
					}}
				>
					{subLabel}
				</FormHelperText>
			)}
		</Box>
	);
}

EditInPlaceInput.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	sx: CommonPropTypes.sx,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	labelSx: CommonPropTypes.sx,
	helperText: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	type: PropTypes.oneOf([
		'email',
		'number',
		'price',
		'percent',
		'tel',
		'text',
		'password',
		'url',
		'visible'
	]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	required: PropTypes.bool
};

EditInPlaceInput.defaultProps = {
	disabled: false,
	error: false,
	helperText: undefined,
	label: undefined,
	labelSx: {},
	onBlur: () => undefined,
	onChange: () => {},
	required: false,
	sx: {},
	type: undefined
};

export default EditInPlaceInput;
