import { Box, Paper, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';

// Our Common Prop types
import CommonProps from 'shared/prop-types';

// Constants
const defaultTableStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	borderBottom: '0.25px black solid',
	padding: 2
};

function AnalyticsPresentationTable({
	tableData,
	children,
	sx,
	firstColumnSX
}) {
	const key = useMemo(() => uuidv4(), [tableData]);

	return (
		<Paper
			key={key}
			elevation={2}
			sx={{
				width: '100%',
				borderRadius: '20px',
				minHeight: 243,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
				...sx
			}}
		>
			<Box sx={defaultTableStyles}>
				<Box sx={{ bgcolor: 'white', ...firstColumnSX }} />
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						minWidth: 86
					}}
				>
					<Typography variant="subtitle2">Current Loan</Typography>
				</Box>
				<Box sx={{ width: 150 }}>
					<Typography variant="subtitle2">
						Alternative Option
					</Typography>
				</Box>
			</Box>
			{tableData}
			{children}
		</Paper>
	);
}

AnalyticsPresentationTable.propTypes = {
	sx: CommonProps.sx,
	firstColumnSX: CommonProps.sx
};

AnalyticsPresentationTable.defaultProps = {
	sx: {},
	firstColumnSX: {
		width: 110
	}
};

export default AnalyticsPresentationTable;
