function BoxIntegrationiFrame() {
	return (
		<iframe
			title="BoxSecureUpload"
			src="https://app.box.com/f/2eed0956ad484485b529ea9553b1a3b4"
			height="620"
			width="800"
		/>
	);
}

export default BoxIntegrationiFrame;
