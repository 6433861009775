import NavIcon from 'components/NavMenu/NavBarComponents/NavIcon';

// Material-UI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';

// Our Icons
import PriceLoanIcon from 'assets/icons/nav_icons/Price_Loan.svg';
import LoanPipelineIcon from 'assets/icons/nav_icons/Loan_Pipeline.svg';

// Routes
import {
	ADVISOR_ANALYTICS_INTRO,
	ADVISOR_INTEGRATIONS_ROUTE,
	DASHBOARD_ROUTE,
	LOAN_INQUIRY_ROUTE,
	LOAN_PIPELINE_ROUTE
} from 'routes/index';

const ADVISOR_NAV_OPTIONS = [
	{
		icon: <DashboardOutlinedIcon />,
		title: 'Dashboard',
		route: DASHBOARD_ROUTE
	},
	{
		icon: <NavIcon src={PriceLoanIcon} alt="Price a Loan" />,
		title: 'Price a Loan',
		route: LOAN_INQUIRY_ROUTE
	},
	{
		icon: <NavIcon src={LoanPipelineIcon} alt="Loan Pipeline" />,
		title: 'Loan Pipeline',
		route: LOAN_PIPELINE_ROUTE
	},
	{
		icon: <ShowChartIcon />,
		title: 'Analytics',
		route: ADVISOR_ANALYTICS_INTRO
	},
	{
		icon: <AddCircleOutlineIcon />,
		title: 'Integrations',
		route: ADVISOR_INTEGRATIONS_ROUTE
	}
];

export default ADVISOR_NAV_OPTIONS;
