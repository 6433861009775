import { List } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

// Icons
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// Our Components
import NavItem from 'components/NavMenu/NavBarComponents/NavItem';

// Routes
import { SUPPORT_ROUTE } from 'routes';

// Hooks
import useMutateSignOutUser from 'hooks/useMutateSignOutUser';

// Local Constants
const CLIENT_SUB_NAV_OPTIONS = [
	{
		title: 'Settings',
		icon: <SettingsIcon />,
		route: '/settings'
	},
	{
		title: 'Sign out',
		icon: <LogoutOutlinedIcon />,
		route: '/'
	}
];

const ADVISOR_SUB_NAV_OPTIONS = [
	{
		title: 'Support',
		icon: <SupportOutlinedIcon />,
		route: SUPPORT_ROUTE
	}
].concat(CLIENT_SUB_NAV_OPTIONS);

function NavSubMenu({ isClient, variant }) {
	const SoraTheme = useTheme();
	const { indigo } = SoraTheme.palette.primary;
	const navigate = useNavigate();

	const signOut = useMutateSignOutUser();

	// Handle user sign out
	const signOutUser = () => {
		signOut.mutate();
	};

	const navOptions = isClient
		? CLIENT_SUB_NAV_OPTIONS
		: ADVISOR_SUB_NAV_OPTIONS;

	const memoizedNavOptions = useMemo(
		() =>
			navOptions.map(({ title, icon, route }) => {
				const isSignOut = title === 'Sign out';

				return (
					<NavItem
						key={uuidv4()}
						title={title}
						route={route}
						icon={icon}
						variant={variant}
						onClick={() => {
							if (isSignOut) {
								signOutUser();
							} else {
								navigate(route);
							}
						}}
						color={indigo}
					/>
				);
			}),

		[navOptions, variant, indigo, navigate]
	);
	return (
		<List sx={{ marginLeft: 2, paddingBottom: 4 }}>
			{memoizedNavOptions}
		</List>
	);
}

NavSubMenu.propTypes = {
	isClient: PropTypes.bool,
	variant: PropTypes.oneOf(['desktop', 'mobile'])
};

NavSubMenu.defaultProps = {
	isClient: false,
	variant: 'desktop'
};

export default NavSubMenu;
