import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';
import { isDate } from 'validator';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import TextInput from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import { NUMBER } from 'components/Input/Types';
import RateInput from 'components/Input/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import SoraTextField from 'components/Input/SoraTextField';
import TenureDropdown from 'components/Client/Onboarding/TenureDropdown';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import {
	EMPLOYMENT_STATUS_OPTIONS,
	PERSONAL,
	REFINANCE_OFFER_TYPE
} from 'shared/constants';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

function PersonalLoanRefinanceForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	refinancingTradelineId,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	assetsAmount,
	cityValue,
	clientId,
	creditScoreValue,
	currentLoanMonthlyPaymentValue,
	currentTermValue,
	disableAutoComplete,
	dobValue,
	emailAddressValue,
	employmentStartDate,
	employmentStatusValue,
	firstName,
	interestRate,
	lastName,
	loanRequestId,
	monthlyHousingPaymentValue,
	outstandingBalance,
	postSuccessEvent,
	rentOrOwnValue,
	setDisableAutoComplete,
	stateValue,
	yearsRemaining,
	zipCodeValue,
	loanType
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const updateLoanInquiry = useMutateUpdateLoanInquiry();
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [tradelineId, setTradelineId] = useState('');

	// Pre-populated state items
	const [dob, setDob] = useState(dobValue || '');
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [totalAssetsValue, setTotalAssetsValue] = useState(assetsAmount || 0);
	const [startDate, setStartDate] = useState(employmentStartDate || '');
	const [employmentStatus, setEmploymentStatus] = useState(
		employmentStatusValue || 'Yes'
	);

	// Existing loan info
	const [currentLoanOutstandingBalance, setCurrentLoanOutstandingBalance] =
		useState(0);
	const [currentInterestRate, setCurrentInterestRate] = useState(0);
	const [currentTerm, setCurrentTerm] = useState(currentTermValue || '');
	const [currentMonthlyPayment, setCurrentMonthlyPayment] = useState(
		currentLoanMonthlyPaymentValue || ''
	);
	const [rentOrOwn, setRentOrOwn] = useState(rentOrOwnValue || '');
	const [monthlyHousingPayment, setMonthlyHousingPayment] = useState(
		monthlyHousingPaymentValue || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [
		currentLoanOutstandingBalance,
		currentInterestRate,
		currentTerm
	];

	const loanRequestDetailsRequiredFields = [
		totalAnnualIncome,
		totalAssetsValue,
		currentLoanOutstandingBalance,
		currentInterestRate,
		currentTerm,
		rentOrOwn,
		monthlyHousingPayment,
		employmentStatus,
		startDate,
		addressLine1,
		city,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			) && isClientNameProvided,
		atLoanRequestDetailsUrl
			? loanRequestDetailsRequiredFields
			: [...formData, isClientNameProvided]
	);

	useEffect(() => {
		if (refinancingTradelineId) {
			setTradelineId(refinancingTradelineId);
		}
	}, [refinancingTradelineId]);

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	useEffect(
		() =>
			setMessage(`
			<div style="text-align: left;">
				<p>The advisor ${advisorName} has a Personal Loan Refinance Request for one of their clients.</p>

				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">	
					<p>Advisor making request:</p>
					<p>Advisor's name: ${advisorName}</p>
					<p>Advisor's email: ${advisorEmail}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
					<p>They made a Personal Loan Refinance Request for the following Client:</p>
				    ${
						clientId
							? `<p>Client lookup url: <a href="${ADMIN_URL}/clients/${clientId}" target="_blank" rel="noopener noreferrer">Admin lookup link</a></p>`
							: ''
					}

					<p>Client's name: ${fullNameValue}</p>
					<p>Client's Email address: ${emailAddress}</p>
					<p>Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}</p>
					<p>Client's Credit score: ${creditScore}</p>
					<p>Client's Date of birth: ${dob}</p>
					<p>Client's Total Annual Income: ${totalAnnualIncome}</p>
					<p>Client's Assets Value: ${totalAssetsValue}</p>
					<p>Is Client Employed? ${employmentStatus}</p>
					<p>Client's Employment Start Date: ${startDate}</p>
					<p>Client's Monthly Housing Payment: ${monthlyHousingPayment}</p>
					<p>Client's residence status: ${rentOrOwn}</p>

					<p>Current Loan Details:</p>
					<p>Monthly Payment of current loan: ${currentMonthlyPayment}</p>
					<p>Outstanding Balance of Current Loan: ${currentLoanOutstandingBalance}</p>
					<p>Interest Rate of Current Loan: ${currentInterestRate}</p>
					<p>Years Remaining of Current Loan: ${currentTerm}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px;">
					<p>Additional Notes:</p>

					${additionalNotes}
				</div>
			</div>
		`),
		[
			...formData,
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			creditScore,
			currentInterestRate,
			currentMonthlyPayment,
			currentLoanOutstandingBalance,
			currentTerm,
			dob,
			emailAddress,
			employmentStatus,
			monthlyHousingPayment,
			rentOrOwn,
			startDate,
			state,
			totalAnnualIncome,
			totalAssetsValue,
			zipCode
		]
	);

	useEffect(() => {
		if (outstandingBalance) {
			const formattedOutstandingBalance = outstandingBalance.toString();
			setCurrentLoanOutstandingBalance(formattedOutstandingBalance);
		}
		if (interestRate) {
			setCurrentInterestRate(interestRate);
		}
		if (yearsRemaining) {
			setCurrentTerm(yearsRemaining);
		}
	}, [outstandingBalance, interestRate, yearsRemaining]);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Personal Loan Refinance Request - ${firstName} ${lastName}`;

		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					setIsSuccessModalOpen(true);
					clearFormValues([
						setDob,
						setCreditScore,
						setTotalAnnualIncome,
						setTotalAssetsValue,
						setStartDate,
						setEmploymentStatus,
						setCurrentLoanOutstandingBalance,
						setCurrentMonthlyPayment,
						setMonthlyHousingPayment,
						setRentOrOwn,
						setCurrentInterestRate,
						setCurrentTerm,
						setAddressLine1,
						setAddressLine2,
						setState,
						setCity,
						setZipCode,
						setEmailAddress,
						setAdditionalNotes
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			currentInterestRate,
			currentMonthlyPayment,
			currentLoanOutstandingBalance,
			currentTerm,
			dob,
			emailAddress,
			employmentStatus,
			firstName,
			lastName,
			monthlyHousingPayment,
			offerType: REFINANCE_OFFER_TYPE,
			rentOrOwn,
			startDate,
			state,
			totalAnnualIncome,
			totalAssetsValue,
			tradelineType: PERSONAL,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			currentInterestRate,
			currentMonthlyPayment,
			currentLoanOutstandingBalance,
			currentTerm,
			dob,
			emailAddress,
			employmentStatus,
			firstName,
			lastName,
			monthlyHousingPayment,
			offerType: REFINANCE_OFFER_TYPE,
			rentOrOwn,
			startDate,
			state,
			totalAnnualIncome,
			totalAssetsValue,
			tradelineType: PERSONAL,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setDob,
						setCreditScore,
						setTotalAnnualIncome,
						setTotalAssetsValue,
						setStartDate,
						setEmploymentStatus,
						setCurrentLoanOutstandingBalance,
						setCurrentMonthlyPayment,
						setMonthlyHousingPayment,
						setRentOrOwn,
						setCurrentInterestRate,
						setCurrentTerm,
						setAddressLine1,
						setAddressLine2,
						setState,
						setCity,
						setZipCode,
						setEmailAddress,
						setAdditionalNotes
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const submitForm = (event) => {
		event.preventDefault();
		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: totalAssetsValue,
				label: 'Total assets value:'
			},
			{
				type: 'currency',
				value: currentLoanOutstandingBalance,
				label: 'Outstanding loan balance:'
			},
			{
				type: 'currency',
				value: currentMonthlyPayment,
				label: 'Monthly payment of current loan:'
			},
			{
				type: 'rate',
				value: currentInterestRate,
				label: 'Current annual interest rate:'
			},
			{
				type: 'currency',
				value: monthlyHousingPayment,
				label: 'Monthly rent or mortgage payment:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				Remaining term: ${currentTerm}
				Primary residence status: ${rentOrOwn}
				Employment status: ${employmentStatus}
				Employment start date: ${startDate}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				advisorName,
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: outstandingBalance,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});
		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography variant="h2Gascogne">
						Personal Loan Refinance
					</Typography>

					<Typography variant="body2">
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Annual income *'
									: 'Annual income'
							}
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								'data-test': 'annualIncome'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Assets Value */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Total assets value *'
									: 'Total assets value'
							}
							value={totalAssetsValue}
							onChange={setTotalAssetsValue}
							inputProps={{
								'data-test': 'totalAssetsValue'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Outstanding Balance */}
						<OutstandingBalanceInput
							label="Outstanding loan balance *"
							outstandingBalance={currentLoanOutstandingBalance}
							setOutstandingBalance={
								setCurrentLoanOutstandingBalance
							}
							inputProps={{
								'data-test': 'outstandingBalance'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Currently loan monthly payment */}
						<TextInput
							label="Monthly payment of current loan"
							value={currentMonthlyPayment}
							onChange={setCurrentMonthlyPayment}
							type={NUMBER}
							inputProps={{
								'data-test': 'currentMonthlyPayment'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current annual interest rate */}
						<RateInput
							type={NUMBER}
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							label="Current annual interest rate *"
							inputProps={{
								'data-test': 'currentInterestRate'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TenureDropdown
							label="Remaining term *"
							subLabel="Time left in months"
							tenure={currentTerm}
							setTenure={setCurrentTerm}
							dropDownSX={{ width: '100%' }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Rent or own */}
						<Dropdown
							items={[
								'Renter',
								'Homeowner',
								'Live with a family member',
								'Other'
							]}
							selected={rentOrOwn}
							onChange={setRentOrOwn}
							variant="outlined"
							label={
								atLoanRequestDetailsUrl
									? 'Primary residence status *'
									: 'Primary residence status'
							}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Monthly Rent or Mortgage Payment Amount */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Monthly rent or mortgage payment *'
									: 'Monthly rent or mortgage payment'
							}
							value={monthlyHousingPayment}
							onChange={setMonthlyHousingPayment}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employment Status */}
						<Dropdown
							items={EMPLOYMENT_STATUS_OPTIONS}
							selected={employmentStatus}
							onChange={setEmploymentStatus}
							variant="outlined"
							label={
								atLoanRequestDetailsUrl
									? 'Employment status *'
									: 'Employment status'
							}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Start date */}
						<StandardDatePicker
							id="startDate"
							label={
								atLoanRequestDetailsUrl
									? 'Employment start date *'
									: 'Employment start date'
							}
							onChange={setStartDate}
							value={startDate}
							error={startDate === null || !isValidDate}
							errorText="Start date needs to be a valid date"
							inputProps={{
								'data-test': 'startDate'
							}}
						/>
					</Grid>

					<GetFullAddressPartialForm
						label={
							atLoanRequestDetailsUrl ? 'Address *' : 'Address'
						}
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				{!isFormReady && (
					<RequiredFieldsTooltip>
						<span>
							<PrimaryButton
								disabled={!isFormReady}
								onClick={submitForm}
							>
								Submit
							</PrimaryButton>
						</span>
					</RequiredFieldsTooltip>
				)}
				{isFormReady && (
					<PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
				)}
			</Grid>
		</>
	);
}

export default PersonalLoanRefinanceForm;
