import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import MortgageDetailCard from 'components/SoraWallet/Cards/mortgagePropertyData/MortgageDetailCard';

// Utilities
import {
	dollarFormatter,
	roundToDecimal,
	getFormattedLiabilityRate
} from 'shared/utils';
import convertDateToAcceptedFormat from 'shared/utils/formatting/convertDateToDatePickerFormat';
import splitByComma from 'shared/utils/formatting/splitListByCommas';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

// helper fn
function createData(label, value) {
	return { label, value };
}

function getFieldDataWithFallBack(field) {
	return field ?? 'Data Not Found';
}

function DetailedListCard({ cardHeading, currentLoan, variant }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const BalanceOutstanding = currentLoan?.totalOutstandingBalance ?? 0;
	const MonthlyPayment = currentLoan?.monthlyMortgagePayment ?? 0;
	const InterestRate = currentLoan?.interestRate ?? 0;
	const RemainingMonths = currentLoan?.remainingMonth ?? 0;
	const YearsRemaining =
		RemainingMonths < 12 ? '< 1' : roundToDecimal(RemainingMonths / 12, 1);
	const TotalCostOfDebt = currentLoan?.costOfDebt ?? 0;
	const OriginalMortgageBalance = currentLoan?.originalMortgageBalance ?? 0;
	const OriginalMortgageDate = currentLoan?.originalMortgageDate ?? 'N/A';

	const currentLiabilitiesDataCardRows = [
		createData(
			'Balance Outstanding',
			dollarFormatter.format(BalanceOutstanding)
		),
		createData('Monthly Payment', dollarFormatter.format(MonthlyPayment)),
		createData('Interest Rate', getFormattedLiabilityRate(InterestRate)),
		createData('Years Remaining', YearsRemaining),
		createData(
			'Total Cost of Debt',
			dollarFormatter.format(TotalCostOfDebt)
		),
		createData(
			'Original Mortgage Balance',
			dollarFormatter.format(OriginalMortgageBalance)
		),
		createData('Original Mortgage Date', OriginalMortgageDate)
	];

	const AddressLine1 = sentenceCapitalize(
		currentLoan?.home?.addressLine1 ?? 'Data Not Found'
	);
	const City = sentenceCapitalize(
		currentLoan?.home?.city ?? 'Data Not Found'
	);
	const State = currentLoan?.home?.state ?? 'Data Not Found';
	const Zip = currentLoan?.home?.zipcode ?? 'Data Not Found';

	const Address = `${AddressLine1} ${City} ${State}, ${Zip}`;
	const HomeValue = currentLoan?.home?.estimatedValue ?? 0;
	const ConfidenceInValue = `${
		currentLoan?.home?.confidenceScore ?? 'Data Not Found'
	}`;
	const PropertyType = currentLoan?.home?.propertyType ?? 'Data Not Found';

	const PropertyTypeFormatted =
		PropertyType === 'SFR' ? 'Single Family Residence' : PropertyType;
	const YearBuilt = currentLoan?.home?.builtYear ?? 'Data Not Found';
	const RawTotalSquareFootage =
		currentLoan?.home?.toalSqft ?? 'Data Not Found';
	const TotalSquareFootage = `${
		RawTotalSquareFootage === 'Data Not Found'
			? RawTotalSquareFootage
			: `${RawTotalSquareFootage} sq. ft.`
	}`;
	const NumBedrooms = currentLoan?.home?.bedroomCount ?? 'Data Not Found';
	const NumBathrooms = currentLoan?.home?.bathCount ?? 'Data Not Found';
	const OwnersNames = splitByComma(
		currentLoan?.home?.ownerName ?? 'Data not found'
	);

	const OwnersNamesFormatted = sentenceCapitalize(
		OwnersNames?.replaceAll(',', '')?.trim() ?? 'Data Not Found'
	);

	const SellersName = sentenceCapitalize(
		currentLoan?.home?.sellerName ?? 'Data Not Found'
	);
	const RawSalesDate = currentLoan?.home?.saleTxDate ?? 'Data Not Found';
	const SaleDate =
		RawSalesDate === 'Data Not Found'
			? RawSalesDate
			: convertDateToAcceptedFormat(RawSalesDate);
	const SaleAmount = currentLoan?.home?.saleAmount ?? 0;
	const AssessedValue = currentLoan?.home?.assedValueUsd ?? 0;

	const propertyDataCardRows = [
		createData('Address', Address),
		createData('Home Value', dollarFormatter.format(HomeValue)),
		createData('Confidence in Value', ConfidenceInValue),
		createData('Property Type', PropertyTypeFormatted),
		createData('Year Built', YearBuilt),
		createData('Total Square Footage', TotalSquareFootage),
		createData('Bedrooms', NumBedrooms),
		createData('Bathrooms', NumBathrooms),
		createData('Listed Owners Names', OwnersNamesFormatted),
		createData('Seller', SellersName),
		createData('Sales Transaction Date', SaleDate),
		createData('Sales Amount', dollarFormatter.format(SaleAmount)),
		createData('Assessed Value', dollarFormatter.format(AssessedValue))
	];

	return (
		<>
			<Typography
				variant="h2Gascogne"
				sx={{
					display: 'block',
					color: primary.indigo,
					marginBottom: 3
				}}
			>
				{cardHeading}
			</Typography>
			<Grid item xs={12}>
				<CardItem
					withoutButton
					sx={{ padding: 2, paddingLeft: 3, paddingRight: 3 }}
				>
					<Grid container columnSpacing={5.2}>
						{variant === 'liability' ? (
							<>
								<Grid item xs={6}>
									<MortgageDetailCard
										cardDataRows={currentLiabilitiesDataCardRows.slice(
											0,
											4
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<MortgageDetailCard
										cardDataRows={currentLiabilitiesDataCardRows.slice(
											4
										)}
									/>
								</Grid>
							</>
						) : (
							<>
								<Grid item xs={6}>
									<MortgageDetailCard
										cardHeading={cardHeading}
										cardDataRows={propertyDataCardRows.slice(
											0,
											7
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<MortgageDetailCard
										cardHeading={cardHeading}
										cardDataRows={propertyDataCardRows.slice(
											7
										)}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</CardItem>
			</Grid>
		</>
	);
}

DetailedListCard.propTypes = {
	cardHeading: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	currentLoan: PropTypes.object,
	variant: PropTypes.oneOf(['liability', 'property'])
};

DetailedListCard.defaultProps = {
	currentLoan: {},
	variant: 'liability'
};

export default DetailedListCard;
