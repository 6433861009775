import { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import SoraTextField from 'components/Input/SoraTextField';
import { NUMBER } from 'components/Input/Types';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Utils
import { clearFormValues } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import {
	EMPLOYMENT_STATUS_OPTIONS,
	REFINANCE_OFFER_TYPE,
	REVERSEMORTGAGE
} from 'shared/constants';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

function ReverseMortgageForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	refinancingTradelineId,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	cityValue,
	clientId,
	creditScoreValue,
	disableAutoComplete,
	emailAddressValue,
	employmentStatusValue,
	estimatedValue,
	firstName,
	lastName,
	loanRequestId,
	outstandingBalanceValue,
	ownershipTypeValue,
	postSuccessEvent,
	primaryUseValue,
	requestAmountValue,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue,
	loanType
}) {
	const queryClient = useQueryClient();
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [tradelineId, setTradelineId] = useState('');

	// Pre-populated state items
	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [loanAmount, setLoanAmount] = useState(requestAmountValue || '');
	const [employmentStatus, setEmploymentStatus] = useState(
		employmentStatusValue || 'Employed'
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [ownershipType, setOwnershipType] = useState(
		ownershipTypeValue || ''
	);
	const [estimatedHomeValue, setEstimatedHomeValue] = useState(
		estimatedValue || ''
	);
	const [outstandingBalance, setOutstandingBalance] = useState(
		outstandingBalanceValue || ''
	);

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const isFormReady = useMemo(
		() => isClientNameProvided,
		[isClientNameProvided]
	);

	useEffect(() => {
		if (outstandingBalanceValue) {
			setOutstandingBalance(outstandingBalanceValue.toString());
		}
		if (estimatedValue) {
			setEstimatedHomeValue(estimatedValue);
		}
		if (primaryUseValue) {
			if (primaryUseValue === 'PRIMARY') {
				setOwnershipType('Primary Residence');
			}
		}
		if (addressLine1Value) {
			setAddressLine1(addressLine1Value);
		}
		if (addressLine2Value) {
			setAddressLine2(addressLine2Value);
		}
		if (cityValue) {
			setCity(cityValue);
		}
		if (stateValue) {
			setState(`${stateValue}`);
		}
		if (zipCodeValue) {
			setZipCode(zipCodeValue);
		}
		if (refinancingTradelineId) {
			setTradelineId(refinancingTradelineId);
		}
	}, [
		outstandingBalanceValue,
		estimatedValue,
		primaryUseValue,
		addressLine1Value,
		addressLine2Value,
		cityValue,
		stateValue,
		zipCodeValue,
		refinancingTradelineId
	]);

	useEffect(
		() =>
			setMessage(`
			<div style="text-align: left;">
				<p>The advisor ${advisorName} has a Reverse Mortgage Loan Request for one of their clients.</p>

				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">	
					<p>Advisor making request:</p>
					<p>Advisor's name: ${advisorName}</p>
					<p>Advisor's email: ${advisorEmail}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
					<p>They made a Reverse Mortgage Loan Request for the following Client:</p>

					${
						clientId
							? `<p>Client lookup url: <a href="${ADMIN_URL}/clients/${clientId}" target="_blank" rel="noopener noreferrer">Admin lookup link</a></p>`
							: ''
					}

					<p>Client's name: ${fullNameValue}</p>
					<p>Client's Email address: ${emailAddress}</p>
					<p>Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}</p>
					<p>Client's Credit score: ${creditScore}</p>
					<p>Client's Employment status: ${employmentStatus}</p>
					<p>Client's Total Annual Income: ${totalAnnualIncome}</p>
					<p>Request Amount of Reverse Mortgage: ${loanAmount}</p>
					<p>Client's Ownership Type: ${ownershipType}</p>
					<p>Client's Estimated Home Value: ${estimatedHomeValue}</p>
					<p>Client's Outstanding Mortgage Balance: ${outstandingBalance}</p>
				</div>


				<div style="border: 1px solid black; padding: 10px;">
					<p>Additional Notes:</p>

					${additionalNotes}
				</div>
			</div>
		`),
		[
			emailAddress,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			creditScore,
			totalAnnualIncome,
			loanAmount,
			ownershipType,
			estimatedHomeValue,
			outstandingBalance,
			additionalNotes,
			employmentStatus
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Reverse Mortgage Loan Request - ${firstName} ${lastName}`;
		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSettled: () => {
					// Open Success modal
					setIsSuccessModalOpen(true);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			employmentStatus,
			estimatedHomeValue,
			firstName,
			lastName,
			offerType: REFINANCE_OFFER_TYPE,
			outstandingBalance,
			ownershipType,
			loanAmount,
			state,
			totalAnnualIncome,
			tradelineType: REVERSEMORTGAGE,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			employmentStatus,
			estimatedHomeValue,
			firstName,
			lastName,
			offerType: REFINANCE_OFFER_TYPE,
			outstandingBalance,
			ownershipType,
			loanAmount,
			state,
			totalAnnualIncome,
			tradelineType: REVERSEMORTGAGE,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setEmailAddress,
						setCreditScore,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setTotalAnnualIncome,
						setLoanAmount,
						setOwnershipType,
						setEstimatedHomeValue,
						setOutstandingBalance,
						setAdditionalNotes,
						setEmploymentStatus
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired Reverse Mortgage Amount:'
			},
			{
				type: 'currency',
				value: estimatedHomeValue,
				label: 'Estimated Home Value:'
			},
			{
				type: 'currency',
				value: outstandingBalance,
				label: 'Current Mortgage Outstanding Balance:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				Primary Use: ${ownershipType}
				Employment Status: ${employmentStatus}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: \n${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				advisorName,
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography variant="h2Gascogne">
						Reverse Mortgage Loan
					</Typography>

					<Typography variant="body2">
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />
					<Grid item xs={12} md={6}>
						{/* Credit Score */}
						<Input
							label="Credit score"
							value={creditScore}
							onChange={setCreditScore}
							type={NUMBER}
							inputProps={{
								'data-test': 'creditScore'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label="Total annual income"
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								'data-test': 'annualIncome'
							}}
							type={NUMBER}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Desired Amount */}
						<CurrencyTextInput
							label="Desired Reverse Mortgage Amount"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								'data-test': 'desiredLoanAmount'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Estimated Home Value */}
						<CurrencyTextInput
							label="Estimated Home Value"
							subLabel="Estimated price of home"
							value={estimatedHomeValue}
							onChange={setEstimatedHomeValue}
							inputProps={{
								'data-test': 'estimatedHomeValue'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Outstanding Balance */}
						<OutstandingBalanceInput
							label="Outstanding Balance of Current Loan"
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
							inputProps={{
								'data-test': 'outstandingBalance'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Ownership type */}
						<Dropdown
							items={[
								'Primary Residence',
								'Secondary Residence',
								'Investment Property'
							]}
							selected={ownershipType}
							onChange={setOwnershipType}
							variant="outlined"
							label="Primary Use of Property"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employment Status */}
						<Dropdown
							items={EMPLOYMENT_STATUS_OPTIONS}
							selected={employmentStatus}
							onChange={setEmploymentStatus}
							variant="outlined"
							label="Employment status"
						/>
					</Grid>

					{/* Address Rows */}
					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={12}>
				<PrimaryButton disabled={!isFormReady} onClick={submitForm}>
					Submit
				</PrimaryButton>
			</Grid>
		</>
	);
}

export default ReverseMortgageForm;
