import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

// our components
import AssetsIncomeCard from 'components/SoraWallet/Cards/AssetsIncomeCard';
import CreditAndDebtToIncomeCard from 'components/SoraWallet/Cards/CreditAndDebtToIncomeCard';
import Loader from 'components/Loader';
import SoraInsightsCarousel from 'components/Carousel/SoraInsightsCarousel';
import TotalLiabilitiesTable from 'components/SoraWallet/Cards/TotalLiabilitiesTable';
import UntappedSavingsUpsidePotentialCard from 'components/SoraWallet/Cards/UpsidePotential/UpsidePotentialUntappedSavingsCard';
import DebtToleranceCard from 'components/Debt-Tolerance/DebtToleranceCard';

// Our Query Keys
import { CLIENT_DATA } from 'shared/query-keys';

// our hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';
import useQueriesInsightsToChatGpt from 'hooks/insights/useQueriesInsightsToChatGptResult';
import useGetAllRuleEngineInsights from 'hooks/insights/useGetAllRuleEngineInsights';

// Our Constants
import { COMMERCIAL } from 'shared/constants';

function SoraWalletOverview({ isClient }) {
	const queryClient = useQueryClient();
	const pageTitle = isClient ? 'Client' : 'FA';
	const {
		isLoading,
		isSuccess,
		data: walletOverviewData
	} = useGetWalletOverview();

	const backEndRuleEngine = useGetAllRuleEngineInsights();

	const insightQueries = useQueriesInsightsToChatGpt(
		isSuccess,
		walletOverviewData
	);

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>{isClient ? 'Client' : 'FA'} Wallet Overview</title>
				</Helmet>
				<Loader
					size={60}
					boxSX={{
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						height: '100%',
						width: '100%',
						marginBottom: 25
					}}
				/>
			</>
		);
	}

	if (isSuccess) {
		const {
			assetsAmount,
			annualIncome,
			clientId,
			creditScore,
			email,
			dti,
			firstName,
			liability,
			lastName,
			totalUntappedSavings,
			totalUpsidePotential
		} = walletOverviewData;

		const clientData = { email, firstName, lastName };

		const { total, liabilities, bizLoans } = liability;

		queryClient.setQueryData(CLIENT_DATA, walletOverviewData);

		const clientDataWithFinancialData = {
			assetsAmount,
			annualIncome,
			creditScore,
			...clientData
		};

		const safeBizLoans = bizLoans ?? [];

		// This exists because the biz loan has different fields than the liabilities
		// Fields that the table wants such as tradeLineType, dataSource, monthlyPay
		const bizLoansWithCorrectedNames = safeBizLoans.map((bizLoan) => {
			const { id, lenderName, lastUpdated, monthlyPayment, rate } =
				bizLoan;

			return {
				...bizLoan,
				dataSource: 'Manual',
				interestRate: rate,
				lender: lenderName,
				lastUpdatedDate: lastUpdated,
				monthlyPay: monthlyPayment,
				rateManuallyUpdated: true,
				tradeLineType: COMMERCIAL,
				tradelineId: id
			};
		});

		const totalLoans = [...liabilities, ...bizLoans].length;

		return (
			<>
				<Helmet>
					<title>{pageTitle} Wallet Overview</title>
				</Helmet>

				<Grid
					container
					item
					xs={12}
					spacing={4}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						paddingRight: 8,
						paddingLeft: 0.5
					}}
				>
					<Grid item xs={12} md={4} xl={4}>
						<CreditAndDebtToIncomeCard
							creditScore={creditScore}
							debtToIncomeRatio={dti}
						/>
					</Grid>

					<Grid item xs={12} md={4} xl={4}>
						<UntappedSavingsUpsidePotentialCard
							upsidePotential={totalUpsidePotential}
							untappedSavings={totalUntappedSavings}
							totalLiabilities={total}
							totalLoans={totalLoans}
						/>
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<AssetsIncomeCard />
					</Grid>

					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<DebtToleranceCard
							isClient={isClient}
							clientData={clientData}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12} sx={{ marginTop: 5 }}>
					<SoraInsightsCarousel
						backEndRuleEngine={backEndRuleEngine}
						clientData={clientDataWithFinancialData}
						insightQueries={insightQueries}
						isClient={isClient}
					/>
				</Grid>

				<Grid
					item
					container
					xs={12}
					sx={{
						marginTop: 1,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						paddingLeft: 0.5,
						paddingRight: 8,
						paddingBottom: 4
					}}
				>
					<Grid item xs={12} sx={{ overflowX: 'auto' }}>
						<TotalLiabilitiesTable
							bizLoans={bizLoansWithCorrectedNames}
							clientId={clientId}
							clientData={clientData}
							totalLiabilities={total}
							liabilities={liabilities}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
}

SoraWalletOverview.propTypes = {
	isClient: PropTypes.bool.isRequired
};

export default SoraWalletOverview;
