import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import RateInput from 'components/Input/RateInput';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import SoraTextField from 'components/Input/SoraTextField';

// Form Input Types
import { NUMBER } from 'components/Input/Types';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';
import usePrepopulateState from 'hooks/prepopulate/usePrepopulateState';

// utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';
import getDwellingType from 'shared/utils/mortgage/getDwellingType';
import getPropertyUse from 'shared/utils/mortgage/getPropertyUse';

// Constants
import {
	MORTGAGE,
	MORTGAGE_LOAN_TENURE_OPTIONS,
	REFINANCE_OFFER_TYPE,
	TYPES_OF_HOME_PROPERTIES
} from 'shared/constants';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

function MortgageRefinanceForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	balanceTenureMonth,
	refinancingTradelineId,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	cityValue,
	clientId,
	creditScoreValue,
	currentOutstandingBalance,
	currentInterestRateValue,
	desiredInterestTypeValue,
	emailAddressValue,
	estimatedValue,
	firstName,
	lastName,
	loanRequestId,
	postSuccessEvent,
	primaryUseValue,
	propertyTypeValue,
	stateValue,
	tenureValue,
	zipCodeValue,
	disableAutoComplete,
	setDisableAutoComplete,
	loanType,
	dwellingType
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState(
		estimatedValue || ''
	);
	const [loanAmount, setLoanAmount] = useState('');
	const [currentInterestRate, setCurrentInterestRate] = useState(
		currentInterestRateValue || ''
	);
	const [tradelineId, setTradelineId] = useState('');
	const [primaryUse, setPrimaryUse] = useState(primaryUseValue || '');
	const [propertyType, setPropertyType] = useState(propertyTypeValue || '');
	const [desiredInterestType, setDesiredInterestType] = useState(
		desiredInterestTypeValue || ''
	);

	const [remainingTerm, setRemainingTerm] = useState(
		balanceTenureMonth || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [ownershipTenure, setOwnershipTenure] = useState(tenureValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [
		propertyType,
		primaryUse,
		loanAmount,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const loanRequestDetailsRequiredFields = [
		propertyType,
		primaryUse,
		loanAmount,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode,
		remainingTerm,
		totalAnnualIncome
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			) && isClientNameProvided,
		atLoanRequestDetailsUrl
			? loanRequestDetailsRequiredFields
			: [...formData, isClientNameProvided]
	);

	usePrepopulateState(
		[
			{
				setState: setPrimaryUse,
				value: getPropertyUse(primaryUseValue)
			},
			{
				setState: setEstimatedPurchaseValue,
				value: estimatedValue
			},
			{
				setState: setCity,
				value: cityValue
			},
			{
				setState,
				value: `${stateValue}`
			},
			{
				setState: setTradelineId,
				value: refinancingTradelineId
			},
			{
				setState: setAddressLine1,
				value: addressLine1Value
			},
			{
				setState: setAddressLine2,
				value: addressLine2Value
			},
			{
				setState: setZipCode,
				value: zipCodeValue
			},
			{
				setState: setCurrentInterestRate,
				value: `${currentInterestRateValue}`
			},
			{
				setState: setLoanAmount,
				value: `${currentOutstandingBalance}`
			},
			{
				setState: setRemainingTerm,
				value: Math.floor(balanceTenureMonth / 12)
			},
			{
				setState: setPropertyType,
				value: getDwellingType(dwellingType)
			}
		],

		[
			balanceTenureMonth,
			cityValue,
			refinancingTradelineId,
			currentOutstandingBalance,
			currentInterestRateValue,
			estimatedValue,
			primaryUseValue,
			stateValue,
			zipCodeValue,
			addressLine1Value,
			addressLine2Value,
			dwellingType
		]
	);

	useEffect(
		() =>
			setMessage(`
				<div style="text-align: left;">
				<p style="margin: 2px 0;">The advisor ${advisorName} has requested a Mortgage Loan Refinance for one of their clients.</p>

				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">	
					<p style="margin: 2px 0;">Advisor making request:</p>
					<p style="margin: 2px 0;">Advisor's name: ${advisorName}</p>
					<p style="margin: 2px 0;">Advisor's email: ${advisorEmail}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
					<p style="margin: 2px 0;">They made a request for a Mortgage Loan Refinance for the following client:</p>

					${
						clientId
							? `<p>Client lookup url: <a href="${ADMIN_URL}/clients/${clientId}" target="_blank" rel="noopener noreferrer">Admin lookup link</a></p>`
							: ''
					}

					<p style="margin: 2px 0;">Client's name: ${fullNameValue}</p>
					<p style="margin: 2px 0;">Client's Email address: ${emailAddress}</p>
					<p style="margin: 2px 0;">Address of home being refinanced: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}</p>
					<p style="margin: 2px 0;">Client's credit score: ${creditScore}</p>
					<p style="margin: 2px 0;">Client's total annual income: ${totalAnnualIncome}</p>
					<p style="margin: 2px 0;">Estimated purchase value of home being refinanced: ${estimatedPurchaseValue}</p>
					<p style="margin: 2px 0;">Client's Current Mortgage Balance: ${loanAmount}</p>
					<p style="margin: 2px 0;">Client's Current Mortgage Interest Rate: ${currentInterestRate}</p>
					<p style="margin: 2px 0;">Primary use of property: ${primaryUse}</p>
					<p style="margin: 2px 0;">Property type: ${propertyType}</p>
					<p style="margin: 2px 0;">Client's expected ownership tenure: ${ownershipTenure}</p>
					<p style="margin: 2px 0;">Client's remaining term of current loan ${remainingTerm}</p>
					<p style="margin: 2px 0;">Client's desired interest type: ${desiredInterestType}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px;">
					<p style="margin: 2px 0;">Additional Notes:</p>

					${additionalNotes}
				</div>
			</div>
		`),
		[
			additionalNotes,
			desiredInterestType,
			city,
			creditScore,
			loanAmount,
			estimatedPurchaseValue,
			remainingTerm,
			ownershipTenure,
			primaryUse,
			propertyType,
			state,
			totalAnnualIncome,
			zipCode,
			currentInterestRate,
			advisorName,
			advisorEmail,
			fullNameValue,
			emailAddress,
			addressLine1,
			addressLine2
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Mortgage Loan Refinance Request - ${firstName} ${lastName}`;
		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setLoanAmount,
						setCity,
						setCreditScore,
						setEmailAddress,
						setAddressLine1,
						setAddressLine2,
						setEstimatedPurchaseValue,
						setRemainingTerm,
						setDesiredInterestType,
						setOwnershipTenure,
						setPrimaryUse,
						setPropertyType,
						setCurrentInterestRate,
						setState,
						setTotalAnnualIncome,
						setZipCode
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			currentInterestRate: +currentInterestRate,
			desiredInterestType,
			estimatedPurchaseValue,
			firstName,
			lastName,
			loanAmount,
			offerType: REFINANCE_OFFER_TYPE,
			ownershipTenure,
			primaryUse,
			propertyType,
			remainingTerm,
			state,
			totalAnnualIncome,
			tradelineId,
			tradelineType: MORTGAGE,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			creditScore,
			desiredInterestType,
			estimatedPurchaseValue,
			remainingTerm,
			firstName,
			lastName,
			loanAmount,
			offerType: REFINANCE_OFFER_TYPE,
			ownershipTenure,
			primaryUse,
			propertyType,
			currentInterestRate,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setLoanAmount,
						setCity,
						setCreditScore,
						setDesiredInterestType,
						setEmailAddress,
						setEstimatedPurchaseValue,
						setRemainingTerm,
						setOwnershipTenure,
						setPrimaryUse,
						setPropertyType,
						setCurrentInterestRate,
						setState,
						setTotalAnnualIncome,
						setZipCode
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},

			{
				type: 'percent',
				value: currentInterestRate,
				label: 'Current mortgage interest rate:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Current mortgage outstanding balance:'
			},
			{
				type: 'currency',
				value: estimatedPurchaseValue,
				label: 'Estimated Purchase Value:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				Property Type: ${propertyType}
				Primary Use: ${primaryUse}
				Remaining loan term: ${remainingTerm}
				Desired loan term: ${ownershipTenure}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				advisorName,
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography variant="h2Gascogne">
						Mortgage Loan Refinance
					</Typography>

					<Typography variant="body2">
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Type of property input */}
						<Dropdown
							items={[
								'Single family home',
								'Apartment',
								'Townhouse',
								'Condominium',
								'Mobile home',
								'Multi-family home',
								'Bungalow',
								'Farmhouse'
							]}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label="Property type *"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Primary use of property */}
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={primaryUse}
							onChange={setPrimaryUse}
							variant="outlined"
							label="Property use *"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Outstanding Mortgage Balance */}
						<CurrencyTextInput
							label="Current mortgage outstanding balance *"
							value={loanAmount}
							onChange={setLoanAmount}
							inputProps={{
								'data-test': 'outstandingBalanceCurrentMortgage'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Interest Rate */}
						<RateInput
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							label="Current mortgage interest rate *"
							inputProps={{
								'data-test': 'interestRateCurrentMortgage'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Remaining term */}
						<Input
							type={NUMBER}
							label={
								atLoanRequestDetailsUrl
									? 'Remaining loan term *'
									: 'Remaining loan term'
							}
							subLabel="Length of loan in years"
							onChange={setRemainingTerm}
							value={remainingTerm}
							inputProps={{
								'data-test': 'Desired loan term'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Loan Tenure */}
						<Dropdown
							items={MORTGAGE_LOAN_TENURE_OPTIONS}
							selected={ownershipTenure}
							onChange={setOwnershipTenure}
							variant="outlined"
							label="Desired loan term *"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Annual income *'
									: 'Annual income'
							}
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								'data-test': 'annualIncome'
							}}
						/>
					</Grid>

					<GetFullAddressPartialForm
						isRequired
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				{!isFormReady && (
					<RequiredFieldsTooltip>
						<span>
							<PrimaryButton
								disabled={!isFormReady}
								onClick={submitForm}
							>
								Submit
							</PrimaryButton>
						</span>
					</RequiredFieldsTooltip>
				)}
				{isFormReady && (
					<PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
				)}
			</Grid>
		</>
	);
}

export default MortgageRefinanceForm;
