import { Typography, Box } from '@mui/material';

// Our Icons
import GroupSvg from 'assets/icons/company_logo/Group.svg';
import BusinessSvg from 'assets/icons/company_logo/Business.svg';

const ICON_SIZE = 200;

function ProfilePicturePlaceHolder({ withDraggable, isCorporate }) {
	const dragMessage = isCorporate
		? 'Drag company logo here'
		: 'Drag profile photo here';

	return (
		<>
			{!isCorporate && (
				<Box
					component="img"
					src={GroupSvg}
					sx={{
						width: ICON_SIZE,
						height: ICON_SIZE,
						background: '#D9D9D9',
						borderRadius: '50%',
						boxSizing: 'border-box'
					}}
				/>
			)}

			{isCorporate && (
				<Box
					component="img"
					src={BusinessSvg}
					sx={{
						width: ICON_SIZE,
						height: ICON_SIZE,
						background: '#D9D9D9',
						borderRadius: '50%',
						p: 4,
						boxSizing: 'border-box'
					}}
				/>
			)}
			{withDraggable && (
				<Typography variant="subtitle1">{dragMessage}</Typography>
			)}
		</>
	);
}

export default ProfilePicturePlaceHolder;
