import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import SoraTextField from 'components/Input/SoraTextField';
import USStateDropdown from 'components/Dropdown/USStateDropdown';
import { PrimaryButton } from 'components/Button/Button';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import { NUMBER, TEXT } from 'components/Input/Types';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Utils
import { clearFormValues } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import { LOT, NEW_LOAN_OFFER_TYPE } from 'shared/constants';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

function LotLoanForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	acresValue,
	additionalNotesValue,
	annualIncome,
	clientId,
	countyValue,
	creditScoreValue,
	desiredLoanTermValue,
	downPaymentPercentValue,
	emailAddressValue,
	estimatedPurchaseAmount,
	firstName,
	landUseValue,
	lastName,
	loanAmountValue,
	loanRequestId,
	postSuccessEvent,
	stateValue,
	timeUntilConstructionValue,
	loanType
}) {
	const queryClient = useQueryClient();
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [state, setState] = useState(stateValue || '');
	const [county, setCounty] = useState(countyValue || '');
	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState(
		estimatedPurchaseAmount || ''
	);
	const [downPaymentPercent, setDownPaymentPercent] = useState(
		downPaymentPercentValue || ''
	);
	const [acres, setAcres] = useState(acresValue || '');
	const [landUse, setLandUse] = useState(landUseValue || '');
	const [timeUntilConstruction, setTimeUntilConstruction] = useState(
		timeUntilConstructionValue || ''
	);
	const [desiredLoanTerm, setDesiredLoanTerm] = useState(
		desiredLoanTermValue || ''
	);

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const isFormReady = useMemo(
		() => isClientNameProvided,
		[isClientNameProvided]
	);

	useEffect(
		() =>
			setMessage(`
			<div style="text-align: left;">
				<p>The advisor ${advisorName} has request a Lot Loan for one of their clients.</p>
				
				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
					<p>Advisor making request:</p>
					<p>Advisor's name: ${advisorName}</p>
					<p>Advisor's email: ${advisorEmail}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
					<p>They made a request for Lot Loan for the following Client:</p>

					${
						clientId
							? `<p>Client lookup url: <a href="${ADMIN_URL}/clients/${clientId}" target="_blank" rel="noopener noreferrer">Admin lookup link</a></p>`
							: ''
					}

					<p>Client's name: ${fullNameValue}</p>
					<p>Client's Email address: ${emailAddress}</p>
					<p>Total Income of all people applying: ${totalAnnualIncome}</p>
					<p>State client is applying in: ${state}</p>
					<p>Client's county: ${county}</p>
					<p>Estimated purchase value: ${estimatedPurchaseValue}</p>
					<p>Down payment percent: ${downPaymentPercent}</p>
					<p># of acres: ${acres}</p>
					<p>Eventual land use: ${landUse}</p>
					<p>Time until construction starts: ${timeUntilConstruction}</p>
					<p>Desired loan term: ${desiredLoanTerm}</p>
					<p>Desired loan amount: ${loanAmount}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px;">
					<p>AdditionalNotes:</p>

					${additionalNotes}
				</div>
			</div>
		`),
		[
			acres,
			additionalNotes,
			county,
			creditScore,
			desiredLoanTerm,
			downPaymentPercent,
			emailAddress,
			estimatedPurchaseValue,
			fullNameValue,
			landUse,
			loanAmount,
			state,
			timeUntilConstruction,
			totalAnnualIncome
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Lot Loan Request - ${firstName} ${lastName}`;
		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setCreditScore,
						setLoanAmount,
						setTotalAnnualIncome,
						setEstimatedPurchaseValue,
						setState,
						setCounty,
						setDownPaymentPercent,
						setAcres,
						setLandUse,
						setTimeUntilConstruction,
						setDesiredLoanTerm,
						setAdditionalNotes,
						setEmailAddress
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			acres,
			additionalNotes,
			clientId,
			county,
			creditScore,
			desiredLoanTerm,
			downPaymentPercent,
			emailAddress,
			loanAmount,
			estimatedPurchaseValue,
			firstName,
			landUse,
			lastName,
			offerType: NEW_LOAN_OFFER_TYPE,
			state,
			timeUntilConstruction,
			totalAnnualIncome,
			tradelineType: LOT
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			acres,
			additionalNotes,
			clientId,
			county,
			creditScore,
			desiredLoanTerm,
			downPaymentPercent,
			emailAddress,
			estimatedPurchaseValue,
			firstName,
			landUse,
			lastName,
			loanAmount,
			offerType: NEW_LOAN_OFFER_TYPE,
			state,
			timeUntilConstruction,
			totalAnnualIncome,
			tradelineType: LOT
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setCreditScore,
						setLoanAmount,
						setTotalAnnualIncome,
						setEstimatedPurchaseValue,
						setState,
						setCounty,
						setDownPaymentPercent,
						setAcres,
						setLandUse,
						setTimeUntilConstruction,
						setDesiredLoanTerm,
						setAdditionalNotes,
						setEmailAddress
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired loan amount:'
			},
			{
				type: 'currency',
				value: estimatedPurchaseValue,
				label: 'Estimated Purchase Value:'
			},
			{
				type: 'percent',
				value: downPaymentPercent,
				label: 'Down payment percent:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				Desired loan Term in years: ${desiredLoanTerm}
				County you are buying in: ${county}
				State: ${state}
				Number of acres: ${acres}
				Eventual use of land: ${landUse}
				Time until construction starts in months: ${timeUntilConstruction}
				------------------------------------------------------------------------------------------------
				Advisor's notes: \n${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				advisorName,
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography variant="h2Gascogne">Lot Loan</Typography>

					<Typography variant="body2">
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}
					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Credit Score */}
						<Input
							label="Credit score"
							value={creditScore}
							onChange={setCreditScore}
							type={NUMBER}
							inputProps={{
								'data-test': 'creditScore'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Desired Loan Amount */}
						<CurrencyTextInput
							label="Desired loan amount"
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								'data-test': 'loanAmount'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Desired loan term */}
						<Input
							label="Desired loan term (# of years)"
							value={desiredLoanTerm}
							onChange={setDesiredLoanTerm}
							type={NUMBER}
							inputProps={{
								'data-test': 'desiredLoanTerm'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label="Total annual income (of everyone applying)"
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								'data-test': 'annualIncome'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* County / State */}
						<Input
							label="County you are buying in"
							value={county}
							onChange={setCounty}
							type={TEXT}
							inputProps={{
								'data-test': 'countyState'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* State */}
						<USStateDropdown
							state={state}
							setState={setState}
							inputProps={{
								'data-test': 'state'
							}}
							gridColumns={12}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Estimated Home Value */}
						<CurrencyTextInput
							label="Estimated Purchase Value"
							subLabel="Estimated price of property"
							value={estimatedPurchaseValue}
							onChange={setEstimatedPurchaseValue}
							inputProps={{
								'data-test': 'estimatedPurchaseValue'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Down payment % */}
						<Input
							type="percent"
							label="Down payment %"
							helperText="Down payment cannot be empty"
							onChange={setDownPaymentPercent}
							value={downPaymentPercent}
							inputProps={{
								'data-test': 'downPaymentPercent'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Acres */}
						<Input
							label="Number of acres"
							subLabel="Approximate number of acres of property"
							value={acres}
							onChange={setAcres}
							type={NUMBER}
							inputProps={{
								'data-test': 'numAcres'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Land use input */}
						<Input
							label="Eventual use of land"
							subLabel="(e.g. secondary residence, investment property)"
							value={landUse}
							onChange={setLandUse}
							type={TEXT}
							inputProps={{
								'data-test': 'landUse'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Time until construction starts */}
						<Input
							label="Time until construction starts (months)"
							value={timeUntilConstruction}
							onChange={setTimeUntilConstruction}
							type={NUMBER}
							inputProps={{
								'data-test': 'timeUntilConstruction'
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={12}>
				{!isFormReady && (
					<RequiredFieldsTooltip>
						<span>
							<PrimaryButton
								disabled={!isFormReady}
								onClick={submitForm}
							>
								Submit
							</PrimaryButton>
						</span>
					</RequiredFieldsTooltip>
				)}
				{isFormReady && (
					<PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
				)}
			</Grid>
		</>
	);
}

export default LotLoanForm;
