import { Navigate } from 'react-router-dom';

// Our Components
import Loader from 'components/Loader/index';

// our hooks
import useGetProfile from 'hooks/useGetProfile';

// Expected
function ProtectedRoute({ element }) {
	const { isError, isLoading, isSuccess } = useGetProfile();

	if (isError) return <Navigate to="/login" />;

	if (isLoading) return <Loader />;

	if (isSuccess) {
		return element;
	}

	return element;
}

export default ProtectedRoute;
