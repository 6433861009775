import PropTypes from 'prop-types';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// Utility function
const createTenureRange = () => {
	const numbers = [];
	// eslint-disable-next-line no-plusplus
	for (let num = 0; num <= 40; num++) {
		numbers.push(`${num}`);
	}
	return numbers;
};

// CONSTANTS
const TENURE_RANGE = createTenureRange();

function TenureDropdown({
	dropDownSX,
	dropDownLabelSX,
	label,
	tenure,
	setTenure,
	tabIndex
}) {
	return (
		<Dropdown
			helperText="Length of loan in years."
			items={TENURE_RANGE}
			label={label}
			labelSx={dropDownLabelSX}
			onChange={setTenure}
			selected={tenure}
			sx={dropDownSX}
			variant="outlined"
			tabIndex={tabIndex}
		/>
	);
}

TenureDropdown.propTypes = {
	dropDownSX: CommonPropTypes.sx,
	dropDownLabelSX: CommonPropTypes.sx,
	setTenure: PropTypes.func.isRequired,
	tenure: PropTypes.string.isRequired,
	tabIndex: PropTypes.string,
	label: PropTypes.string
};

TenureDropdown.defaultProps = {
	tabIndex: '',
	dropDownLabelSX: {},
	dropDownSX: {},
	label: 'Loan length'
};

export default TenureDropdown;
