import { Grid, Typography } from '@mui/material';

// Our Components
import TextInput from 'components/Input/TextInput';

// Our Constants
import { TEXT } from 'components/Input/Types';

function PriceALoanNewClientForm({
	clientFirstName,
	clientLastName,
	setClientFirstName,
	setClientLastName
}) {
	return (
		<Grid
			component="form"
			container
			item
			noValidate
			columnSpacing={2}
			rowSpacing={3}
			autoComplete="off"
			sx={{ marginBottom: 4 }}
		>
			<Grid item xs={12}>
				<Typography variant="h2Gascogne">Client Information</Typography>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextInput
					type={TEXT}
					label="Client First Name *"
					value={clientFirstName}
					onChange={setClientFirstName}
					required
					error={clientFirstName?.length === 0}
					helperText="Please enter the client's first name"
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextInput
					type={TEXT}
					label="Client Last Name *"
					value={clientLastName}
					onChange={setClientLastName}
					required
					error={clientLastName?.length === 0}
					helperText="Please enter the client's last name"
				/>
			</Grid>
		</Grid>
	);
}

export default PriceALoanNewClientForm;
