import { useState } from 'react';
import { Box, Typography } from '@mui/material';

// Our Components
import Modal from 'components/Modal/Modal';
import {
	PrimaryButton,
	SecondaryButton,
	TertiaryButton,
	QuaternaryButton,
	TextButton
} from 'components/Button/Button';

const modes = ['xs', 'sm', 'md', 'lg', 'xl'];
const buttons = [
	{
		label: 'Primary Button',
		mode: 'primary',
		component: PrimaryButton
	},
	{
		label: 'Secondary Button',
		mode: 'secondary',
		component: SecondaryButton
	},
	{
		label: 'Tertiary Button',
		mode: 'tertiary',
		component: TertiaryButton
	},
	{
		label: 'Quaternary Button',
		mode: 'quaternary',
		component: QuaternaryButton
	},
	{
		label: 'Text Button',
		mode: 'text',
		component: TextButton
	}
];

function DesignPage() {
	const [open, setOpen] = useState(false);
	const [mode, setMode] = useState('md');

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
					marginLeft: 4
				}}
			>
				<Box>
					<Typography
						variant="subtitle1"
						sx={{ display: 'block', marginTop: 4, marginLeft: 4 }}
					>
						Modal modes available
					</Typography>
					<Box sx={{ display: 'flex', gap: 2, marginLeft: 4 }}>
						{modes.map((currentMode) => (
							<PrimaryButton
								onClick={() => {
									setMode(currentMode);
									setOpen(true);
								}}
							>
								{currentMode}
							</PrimaryButton>
						))}
					</Box>
				</Box>
				<Box>
					<Typography
						variant="subtitle1"
						sx={{ display: 'block', marginTop: 4, marginLeft: 4 }}
					>
						Buttons available
					</Typography>
					<Box sx={{ display: 'flex', gap: 2, marginLeft: 4 }}>
						{buttons.map((currentButton) => (
							<currentButton.component>
								{currentButton.label}
							</currentButton.component>
						))}
					</Box>
				</Box>
			</Box>

			<Modal
				isOpen={open}
				handleClose={() => setOpen(false)}
				maxWidth={mode}
			>
				<Typography variant="subtitle1">
					Current Mode: {mode}
				</Typography>
			</Modal>
		</>
	);
}

export default DesignPage;
