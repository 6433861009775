import { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import TextInput from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import SoraTextField from 'components/Input/SoraTextField';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import { NUMBER } from 'components/Input/Types';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';
import usePrepopulateState from 'hooks/prepopulate/usePrepopulateState';

// Utils
import { clearFormValues, isSubmissionReady } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';
import getDwellingType from 'shared/utils/mortgage/getDwellingType';
import getPropertyUse from 'shared/utils/mortgage/getPropertyUse';

// Constants
import {
	EMPLOYMENT_STATUS_OPTIONS,
	CASHOUT_OFFER_TYPE,
	MORTGAGE,
	MORTGAGE_LOAN_TENURE_OPTIONS,
	TYPES_OF_HOME_PROPERTIES
} from 'shared/constants';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

function getFinalAmount(
	isOutstandingBalancePresent,
	isLoanAmountPresent,
	outstandingBalance,
	loanAmount
) {
	if (isOutstandingBalancePresent && isLoanAmountPresent) {
		return +loanAmount + +outstandingBalance;
	}

	if (isOutstandingBalancePresent) {
		return +outstandingBalance;
	}

	if (isLoanAmountPresent) {
		return +loanAmount;
	}

	return 0;
}

function CashOutRefiForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	refinancingTradelineId,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	cityValue,
	clientId,
	creditScoreValue,
	currentOutstandingBalance,
	currentInterestRateValue,
	desiredInterestTypeValue,
	emailAddressValue,
	estimatedValue,
	firstName,
	lastName,
	loanRequestId,
	postSuccessEvent,
	primaryUseValue,
	propertyTypeValue,
	stateValue,
	tenureValue,
	zipCodeValue,
	disableAutoComplete,
	setDisableAutoComplete,
	loanType,
	dwellingType,
	remainingTermValue,
	balanceTenureMonth
}) {
	const queryClient = useQueryClient();
	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	const [tradelineId, setTradelineId] = useState('');

	// Pre-populated state items
	const [fullName, setFullName] = useState(fullNameValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [loanAmount, setLoanAmount] = useState(
		currentOutstandingBalance || ''
	);
	const [employmentStatus, setEmploymentStatus] = useState('Employed');
	const [propertyType, setPropertyType] = useState(propertyTypeValue || '');
	const [primaryUse, setPrimaryUse] = useState(primaryUseValue || '');
	const [currentInterestRate, setCurrentInterestRate] = useState(
		currentInterestRateValue || ''
	);
	const [remainingTerm, setRemainingTerm] = useState(
		remainingTermValue || ''
	);
	const [desiredInterestType, setDesiredInterestType] = useState(
		desiredInterestTypeValue || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [estimatedPurchaseValue, setEstimatedPurchaseValue] = useState(
		estimatedValue || ''
	);
	const [outstandingBalance, setOutstandingBalance] = useState(
		currentOutstandingBalance || ''
	);

	const [ownershipTenure, setOwnershipTenure] = useState('');
	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const formData = [
		isClientNameProvided,
		propertyType,
		primaryUse,
		outstandingBalance,
		estimatedPurchaseValue,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const loanRequestDetailsRequiredFields = [
		propertyType,
		primaryUse,
		outstandingBalance,
		remainingTerm,
		desiredInterestType,
		totalAnnualIncome,
		estimatedPurchaseValue,
		currentInterestRate,
		ownershipTenure,
		city,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() =>
			isSubmissionReady(
				atLoanRequestDetailsUrl
					? loanRequestDetailsRequiredFields
					: formData
			) && isClientNameProvided,
		atLoanRequestDetailsUrl ? loanRequestDetailsRequiredFields : formData
	);

	usePrepopulateState(
		[
			{
				setState: setPrimaryUse,
				value: getPropertyUse(primaryUseValue)
			},
			{
				setState: setEstimatedPurchaseValue,
				value: estimatedValue
			},
			{
				setState: setCity,
				value: cityValue
			},
			{
				setState,
				value: `${stateValue}`
			},
			{
				setState: setTradelineId,
				value: refinancingTradelineId
			},
			{
				setState: setAddressLine1,
				value: addressLine1Value
			},
			{
				setState: setAddressLine2,
				value: addressLine2Value
			},
			{
				setState: setZipCode,
				value: zipCodeValue
			},
			{
				setState: setCurrentInterestRate,
				value: currentInterestRateValue
			},
			{
				setState: setOutstandingBalance,
				value: `${currentOutstandingBalance}`
			},
			{
				setState: setRemainingTerm,
				value: Math.floor(balanceTenureMonth / 12)
			},
			{
				setState: setPropertyType,
				value: getDwellingType(dwellingType)
			}
		],

		[
			balanceTenureMonth,
			cityValue,
			refinancingTradelineId,
			currentOutstandingBalance,
			currentInterestRateValue,
			estimatedValue,
			primaryUseValue,
			stateValue,
			zipCodeValue,
			addressLine1Value,
			addressLine2Value,
			dwellingType
		]
	);

	useEffect(
		() =>
			setMessage(`
				<div style="text-align: left;">
					<p>The advisor ${advisorName} has requested a CashOut Refinance Loan Request for one of their clients.</p>

					<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
						<p>Advisor making request:</p>
						<p>Advisor's name: ${advisorName}</p>
						<p>Advisor's email: ${advisorEmail}</p>
					</div>

					<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
						<p>They made a CashOut Refinance Loan Request for the following Client:</p>

						${
							clientId
								? `<p>Client lookup url: <a href="${ADMIN_URL}/clients/${clientId}" target="_blank" rel="noopener noreferrer">Admin lookup link</a></p>`
								: ''
						}

						<p>Client's name: ${firstName} ${lastName}</p>
						<p>Client's Email address: ${emailAddress}</p>
						<p>Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}</p>
						<p>Client's Credit Score: ${creditScoreValue}</p>
						<p>Client's Employment status: ${employmentStatus}</p>
						<p>Client's Total Annual Income: ${totalAnnualIncome}</p>
						<p>Request Amount of Cash Out Refi: ${loanAmount}</p>
						<p>Client's Ideal loan tenure: ${ownershipTenure}</p>
						<p>Client's Estimated Home Value: ${estimatedPurchaseValue}</p>
						<p>Client's Outstanding Mortgage Balance: ${outstandingBalance}</p>
						<p>Primary use of property: ${primaryUse}</p>
						<p>Property type: ${propertyType}</p>
						<p>Client's Current Mortgage Interest Rate: ${currentInterestRate}</p>
						<p>Client's remaining term of current loan ${remainingTerm}</p>
						<p>Client's desired interest type: ${desiredInterestType}</p>
					</div>

					<div style="border: 1px solid black; padding: 10px;">
						<p>Additional Notes:</p>

						${additionalNotes}
					</div>
				</div>
		`),
		[
			emailAddress,
			city,
			state,
			zipCode,
			totalAnnualIncome,
			loanAmount,
			ownershipTenure,
			estimatedPurchaseValue,
			outstandingBalance,
			additionalNotes,
			employmentStatus,
			advisorName,
			advisorEmail,
			fullName,
			primaryUse,
			propertyType,
			currentInterestRate,
			remainingTerm,
			desiredInterestType,
			addressLine1,
			addressLine2
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Cash Out Refinance Loan Request - ${firstName} ${lastName}`;
		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setEmailAddress,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setTotalAnnualIncome,
						setLoanAmount,
						setOwnershipTenure,
						setEstimatedPurchaseValue,
						setOutstandingBalance,
						setAdditionalNotes,
						setEmploymentStatus,
						setPrimaryUse,
						setPropertyType
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			primaryUse,
			propertyType,
			addressLine1,
			addressLine2,
			city,
			clientId,
			currentInterestRate: +currentInterestRate,
			employmentStatus,
			desiredInterestType,
			estimatedPurchaseValue: +estimatedPurchaseValue,
			firstName,
			lastName,
			offerType: CASHOUT_OFFER_TYPE,
			outstandingBalance,
			remainingTerm,
			ownershipTenure,
			loanAmount,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			primaryUse,
			propertyType,
			city,
			clientId,
			currentInterestRate,
			addressLine1,
			addressLine2,
			employmentStatus,
			desiredInterestType,
			estimatedPurchaseValue,
			firstName,
			lastName,
			offerType: CASHOUT_OFFER_TYPE,
			outstandingBalance,
			remainingTerm,
			ownershipTenure,
			loanAmount,
			state,
			totalAnnualIncome,
			tradelineType: MORTGAGE,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setFullName,
						setEmailAddress,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setTotalAnnualIncome,
						setLoanAmount,
						setOwnershipTenure,
						setEstimatedPurchaseValue,
						setOutstandingBalance,
						setAdditionalNotes,
						setEmploymentStatus,
						setPropertyType,
						setPrimaryUse
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: outstandingBalance,
				label: 'Current Mortgage Outstanding Balance:'
			},
			{
				type: 'currency',
				value: estimatedPurchaseValue,
				label: 'Estimated Home Value:'
			},
			{
				type: 'currency',
				value: currentInterestRate,
				label: 'Current mortgage interest rate:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Desired Cash Out Refinance Amount:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScoreValue}
				${formattedCollection}
				Remaining loan term: ${remainingTerm}
				Desired loan term: ${ownershipTenure}
				Property Type: ${propertyType}
				Primary Use: ${primaryUse}
				Employment Status: ${employmentStatus}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: \n${additionalNotes}`;

		const isOutstandingBalancePresent = outstandingBalance !== '';
		const isLoanAmountPresent = loanAmount !== '';

		const updatedFinalAmount = getFinalAmount(
			isOutstandingBalancePresent,
			isLoanAmountPresent,
			outstandingBalance,
			loanAmount
		);

		createDeal.mutate({
			advisorInfo: {
				advisorName,
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: updatedFinalAmount,
				dealname: `${advisorName} / ${fullName}`,
				dealtype: loanType,
				borrower: fullName,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography variant="h2Gascogne">
						Cash Out Refinance Loan
					</Typography>

					<Typography variant="body2">
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />

					<Grid item xs={12} md={6}>
						{/* Type of property input */}
						<Dropdown
							items={[
								'Single family home',
								'Apartment',
								'Townhouse',
								'Condominium',
								'Mobile home',
								'Multi-family home',
								'Bungalow',
								'Farmhouse'
							]}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label="Property type *"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Primary use of property */}
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={primaryUse}
							onChange={setPrimaryUse}
							variant="outlined"
							label="Property use *"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Mortgage Outstanding Balance */}
						<OutstandingBalanceInput
							label="Current Mortgage Outstanding Balance *"
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
							inputProps={{
								'data-test': 'outstandingBalance'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Estimated Purchase Value */}
						<CurrencyTextInput
							label="Estimated Purchase Value *"
							subLabel="Estimated price of home"
							value={estimatedPurchaseValue}
							onChange={setEstimatedPurchaseValue}
							inputProps={{
								'data-test': 'estimatedPurchaseValue'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Interest Rate */}
						<RateInput
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							label="Current mortgage interest rate *"
							inputProps={{
								'data-test': 'interestRateCurrentMortgage'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Current Loan Remaining term */}
						<TextInput
							type={NUMBER}
							label={
								atLoanRequestDetailsUrl
									? 'Remaining loan term *'
									: 'Remaining loan term'
							}
							subLabel="Length of loan in years"
							onChange={setRemainingTerm}
							value={remainingTerm}
							inputProps={{
								'data-test': 'Remaining loan term'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Loan Tenure */}
						<Dropdown
							items={MORTGAGE_LOAN_TENURE_OPTIONS}
							selected={ownershipTenure}
							onChange={setOwnershipTenure}
							variant="outlined"
							label="Desired loan term *"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Total annual income *'
									: 'Total annual income'
							}
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								'data-test': 'annualIncome'
							}}
							type={NUMBER}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Desired Amount */}
						<CurrencyTextInput
							label={
								atLoanRequestDetailsUrl
									? 'Desired Cash Out Refinance Amount *'
									: 'Desired Cash Out Refinance Amount'
							}
							value={loanAmount}
							onChange={setLoanAmount}
							type={NUMBER}
							inputProps={{
								'data-test': 'desiredLoanAmount'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employment Status */}
						<Dropdown
							items={EMPLOYMENT_STATUS_OPTIONS}
							selected={employmentStatus}
							onChange={setEmploymentStatus}
							variant="outlined"
							label="Employment status"
						/>
					</Grid>

					<GetFullAddressPartialForm
						isRequired
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<SoraTextField
							label="Additional Notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={12}>
				{!isFormReady && (
					<RequiredFieldsTooltip>
						<span>
							<PrimaryButton
								disabled={!isFormReady}
								onClick={submitForm}
							>
								Submit
							</PrimaryButton>
						</span>
					</RequiredFieldsTooltip>
				)}
				{isFormReady && (
					<PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
				)}
			</Grid>
		</>
	);
}

export default CashOutRefiForm;
