import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';
import { isDate } from 'validator';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import SoraTextField from 'components/Input/SoraTextField';
import YearMonthDatePicker from 'components/DatePicker/YearMonthDatePicker';
import { TEXT } from 'components/Input/Types';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Util Hooks
import usePrepopulateState from 'hooks/prepopulate/usePrepopulateState';

// Utils
import { clearFormValues } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants
import {
	EDUCATION_LEVEL_OPTIONS,
	EMPLOYMENT_STATUS_OPTIONS,
	REFINANCE_OFFER_TYPE,
	STUDENT
} from 'shared/constants';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

function StudentLoanRefinanceForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	refinancingTradelineId,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	assetsAmount,
	cityValue,
	clientId,
	creditScore,
	disableAutoComplete,
	dobValue,
	educationLevelValue,
	emailAddressValue,
	employerValue,
	employmentStartDate,
	employmentStatusValue,
	firstName,
	graduationYearValue,
	lastName,
	lenderType,
	loanRequestId,
	monthlyHousingPaymentValue,
	outstandingBalance,
	postSuccessEvent,
	rentOrOwnValue,
	schoolValue,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue,
	loanType,
	currentInterestRateValue,
	studentLoanType
}) {
	const queryClient = useQueryClient();
	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;
	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	const [tradelineId, setTradelineId] = useState('');

	// Build full name
	const fullNameValue = `${firstName} ${lastName}`;

	// Pre-populated state items
	const [employer, setEmployer] = useState(employerValue || '');
	const [employmentStatus, setEmploymentStatus] = useState(
		employmentStatusValue || ''
	);
	const [educationLevel, setEducationLevel] = useState(
		educationLevelValue || ''
	);
	const [startDate, setStartDate] = useState(employmentStartDate || '');
	const [school, setSchool] = useState(schoolValue || '');
	const [graduationDate, setGraduationDate] = useState(
		graduationYearValue || ''
	);
	const [dob, setDob] = useState(dobValue || '');
	const [currentLoanType, setCurrentLoanType] = useState(lenderType || '');
	const [currentOutstandingBalance, setCurrentOutstandingBalance] = useState(
		outstandingBalance || ''
	);
	const [currentInterestRate, setCurrentInterestRate] = useState('');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);
	const [totalAssetsValue, setTotalAssetsValue] = useState(assetsAmount || 0);
	const [rentOrOwn, setRentOrOwn] = useState(rentOrOwnValue || '');
	const [monthlyPayment, setMonthlyPayment] = useState(
		monthlyHousingPaymentValue || ''
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const isFormReady = useMemo(
		() => isClientNameProvided,
		[isClientNameProvided]
	);

	usePrepopulateState(
		[
			{
				setState: setCurrentInterestRate,
				value: `${currentInterestRateValue}`
			},
			{
				setState: setCurrentOutstandingBalance,
				value: `${outstandingBalance}`
			},
			{
				setState: setCurrentLoanType,
				value: `${studentLoanType}`
			}
		],
		[currentInterestRateValue, outstandingBalance]
	);

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	const GRADUATION_YEAR_ONLY_VIEW = ['year']; // This is for the DatePicker of Graduation Year

	useEffect(
		() =>
			setMessage(`
			<div style="text-align: left;">
				<p>The advisor ${advisorName} has a Student Loan Refinance Request for one of their clients.</p>
			
				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">	
					<p>Advisor making request:</p>
					<p>Advisor's name: ${advisorName}</p>
					<p>Advisor's email: ${advisorEmail}</p>
				</div>
			
				<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">
					<p>They made a Student Loan Refinance Request for the following Client:</p>

					${
						clientId
							? `<p>Client lookup url: <a href="${ADMIN_URL}/clients/${clientId}" target="_blank" rel="noopener noreferrer">Admin lookup link</a></p>`
							: ''
					}

					<p>Client's name: ${fullNameValue}</p>
					<p>Client's Email address: ${emailAddress}</p>
					<p>Client's Date of birth: ${dob}</p>
					<p>Client's Credit Score: ${creditScore}</p>
					<p>Client's Employer: ${employer}</p>
					<p>Client's Employment Status: ${employmentStatus}</p>
					<p>Client's Employment Start year: ${startDate}</p>
					<p>Client's Total Annual Income: ${totalAnnualIncome}</p>
					<p>Client's Assets Value: ${totalAssetsValue}</p>
					<p>Client's Education Level: ${educationLevel}</p>
					<p>School Client Attended: ${school}</p>
					<p>Client's Graduation Year: ${graduationDate}</p>
					<p>Does Client rent or own? ${rentOrOwn}</p>
					<p>Client's monthly housing cost: ${monthlyPayment}</p>
					<p>Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}</p>
					<p>Current Loan Details:</p>
					<p>Current Student Loan is: ${currentLoanType}</p>
					<p>Outstanding Balance of Current Loan: ${currentOutstandingBalance}</p>
					<p>Interest Rate of Current Loan: ${currentInterestRate}</p>
				</div>

				<div style="border: 1px solid black; padding: 10px;">
					<p>Additional Notes:</p>

					${additionalNotes}
				</div>	
			</div>
		`),
		[
			emailAddress,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			dob,
			employer,
			employmentStatus,
			startDate,
			totalAnnualIncome,
			totalAssetsValue,
			educationLevel,
			school,
			graduationDate,
			currentLoanType,
			currentInterestRate,
			currentOutstandingBalance,
			additionalNotes,
			rentOrOwn,
			monthlyPayment
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Student Loan Refinance Request - ${firstName} ${lastName}`;
		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setEmailAddress,
						setEmployer,
						setEmploymentStatus,
						setEducationLevel,
						setStartDate,
						setSchool,
						setGraduationDate,
						setDob,
						setCurrentLoanType,
						setCurrentInterestRate,
						setCurrentOutstandingBalance,
						setTotalAnnualIncome,
						setTotalAssetsValue,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setAdditionalNotes,
						setMonthlyPayment,
						setRentOrOwn
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			currentOutstandingBalance,
			currentLoanType,
			currentInterestRate,
			dob,
			educationLevel,
			emailAddress,
			employer,
			employmentStatus,
			firstName,
			graduationDate,
			lastName,
			monthlyPayment,
			offerType: REFINANCE_OFFER_TYPE,
			rentOrOwn,
			school,
			startDate,
			state,
			totalAnnualIncome,
			totalAssetsValue,
			tradelineType: STUDENT,
			tradelineId,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			city,
			clientId,
			currentOutstandingBalance,
			currentLoanType,
			currentInterestRate,
			dob,
			educationLevel,
			emailAddress,
			employer,
			employmentStatus,
			firstName,
			graduationDate,
			lastName,
			monthlyPayment,
			offerType: REFINANCE_OFFER_TYPE,
			rentOrOwn,
			school,
			startDate,
			state,
			totalAnnualIncome,
			totalAssetsValue,
			tradelineType: STUDENT,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setEmailAddress,
						setEmployer,
						setEmploymentStatus,
						setEducationLevel,
						setStartDate,
						setSchool,
						setGraduationDate,
						setDob,
						setCurrentLoanType,
						setCurrentOutstandingBalance,
						setCurrentInterestRate,
						setTotalAnnualIncome,
						setTotalAssetsValue,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setState,
						setZipCode,
						setAdditionalNotes,
						setMonthlyPayment,
						setRentOrOwn
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: totalAssetsValue,
				label: 'Total assets value:'
			},
			{
				type: 'currency',
				value: monthlyPayment,
				label: 'Monthly payment:'
			},
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: currentOutstandingBalance,
				label: 'Outstanding balance:'
			},
			{
				type: 'rate',
				value: currentInterestRate,
				label: 'Current interest rate:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				Client's name: ${fullNameValue}
				Client's dob: ${dob}
				${formattedCollection}
				Employment Status: ${employmentStatus}
				Employer Name: ${employer}
				Employment Start Date: ${startDate}
				Highest degree obtained: ${educationLevel}
				School attended: ${school}
				Graduation year: ${graduationDate}
				Rent or own: ${rentOrOwn}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				Current loan type: ${currentLoanType}

				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				advisorName,
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: '',
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});
		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography variant="h2Gascogne">
						Student Loan Refinance
					</Typography>

					<Typography variant="body2">
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					{sendFormDataError && <Alert variant="error" />}

					{/* Modal shown on successful submission of  */}
					<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />
					<Grid item xs={12} md={6}>
						{/* Date of Birth */}
						<StandardDatePicker
							id="dob"
							label="Date of birth"
							helperText="Enter your birth date"
							onChange={setDob}
							value={dob}
							error={dob === null || !isValidDate}
							errorText="Date of birth needs to be a valid date"
							inputProps={{
								'data-test': 'dateOfBirth'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Annual Income */}
						<CurrencyTextInput
							label="Total annual income"
							value={totalAnnualIncome}
							onChange={setTotalAnnualIncome}
							inputProps={{
								'data-test': 'annualIncome'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Assets Value */}
						<CurrencyTextInput
							label="Total assets value"
							value={totalAssetsValue}
							onChange={setTotalAssetsValue}
							inputProps={{
								'data-test': 'totalAssetsValue'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employment Status */}
						<Dropdown
							items={EMPLOYMENT_STATUS_OPTIONS}
							selected={employmentStatus}
							onChange={setEmploymentStatus}
							variant="outlined"
							label="Employment status"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Employer */}
						<Input
							label="Employer name"
							value={employer}
							onChange={setEmployer}
							type={TEXT}
							inputProps={{
								'data-test': 'employer'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Start date */}
						<StandardDatePicker
							id="startDate"
							label="Employment start date"
							onChange={setStartDate}
							value={startDate}
							error={startDate === null || !isValidDate}
							errorText="Start date needs to be a valid date"
							inputProps={{
								'data-test': 'startDate'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Education Level */}
						<Dropdown
							items={EDUCATION_LEVEL_OPTIONS}
							selected={educationLevel}
							onChange={setEducationLevel}
							variant="outlined"
							label="Highest degree obtained"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* School Attended */}
						<Input
							inputProps={{
								'data-test': 'school'
							}}
							type={TEXT}
							label="School attended"
							value={school}
							onChange={setSchool}
							subLabel="Name of school"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Graduation Month and Year */}
						<YearMonthDatePicker
							helperText="Enter a year like 2022"
							inputFormat="yyyy"
							label="Graduation month and year"
							onChange={setGraduationDate}
							variant="outlined"
							value={graduationDate}
							views={GRADUATION_YEAR_ONLY_VIEW}
							inputProps={{
								'data-test': 'monthAndYearOfGraduation'
							}}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Rent or own */}
						<Dropdown
							items={['Rent', 'Own']}
							selected={rentOrOwn}
							onChange={setRentOrOwn}
							variant="outlined"
							label="Do you rent or own your primary residences?"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Monthly Payment Amount */}
						<CurrencyTextInput
							label="Monthly payment"
							value={monthlyPayment}
							onChange={setMonthlyPayment}
						/>
					</Grid>

					{/* Current Address */}
					<GetFullAddressPartialForm
						label="Current address"
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12}>
						<Typography variant="h3Gascogne">
							Current loan details
						</Typography>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Loan Type */}
						<Dropdown
							dataTestTag="loanType"
							items={['federal', 'private']}
							selected={currentLoanType}
							onChange={setCurrentLoanType}
							variant="outlined"
							label="Lender type"
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						{/* Outstanding Balance */}
						<OutstandingBalanceInput
							label="Outstanding balance of current loan"
							outstandingBalance={currentOutstandingBalance}
							setOutstandingBalance={setCurrentOutstandingBalance}
							inputProps={{
								'data-test': 'outstandingBalance'
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* Current Loan Interest Rate */}
						<RateInput
							rate={currentInterestRate}
							setRate={setCurrentInterestRate}
							label="Current interest rate *"
							inputProps={{
								'data-test': 'currentInterestRate'
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<SoraTextField
							label="Additional notes"
							value={additionalNotes}
							onChange={setAdditionalNotes}
						/>
					</Grid>
				</>
			)}

			<Grid item xs={12}>
				<PrimaryButton disabled={!isFormReady} onClick={submitForm}>
					Submit
				</PrimaryButton>
			</Grid>
		</>
	);
}

export default StudentLoanRefinanceForm;
