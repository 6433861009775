import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import CardHeading from 'components/SoraWallet/Cards/CardHeading';
import SoraToolTip from 'components/Tooltip/index';

// ToolTip data
import WalletToolTips from 'components/SoraWallet/WalletToolTips.json';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function UpsidePotentialCoreData({
	upsidePotential,
	untappedSavings,
	totalLoans,
	totalLiabilities,
	variant
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const formattedUpsidePotential = dollarFormatter.format(upsidePotential);
	const formattedUntappedSavings = dollarFormatter.format(untappedSavings);
	const formattedTotalLiabilities = dollarFormatter.format(totalLiabilities);

	if (variant === 'old') {
		return (
			<>
				<Box>
					<CardHeading
						title="Total Upside Potential"
						sx={{ color: primary.white }}
					>
						<SoraToolTip
							text={WalletToolTips.upsidePotential}
							toolTipSx={{
								color: primary.white,
								marginLeft: 1,
								fontSize: '1.2rem'
							}}
							placement="bottom"
						/>
					</CardHeading>

					<Typography
						variant="h4"
						sx={{ color: primary.white, marginBottom: 2 }}
					>
						{formattedUpsidePotential}
					</Typography>
				</Box>
				<Box>
					<CardHeading
						title="Total Untapped Savings"
						sx={{ color: primary.white }}
					>
						<SoraToolTip
							text={WalletToolTips.untappedSavings}
							toolTipSx={{
								color: primary.white,
								marginLeft: 1,
								fontSize: '1.2rem'
							}}
							placement="bottom"
						/>
					</CardHeading>
					<Typography variant="h4" sx={{ color: primary.white }}>
						{formattedUntappedSavings}
					</Typography>
				</Box>
			</>
		);
	}

	return (
		<>
			<Box>
				<CardHeading
					title="Total Loans"
					sx={{ color: primary.white }}
				/>

				<Typography
					variant="h4"
					sx={{ color: primary.white, marginBottom: 2 }}
				>
					{totalLoans}
				</Typography>
			</Box>
			<Box>
				<CardHeading
					title="Total Liabilities"
					sx={{ color: primary.white }}
				/>

				<Typography variant="h4" sx={{ color: primary.white }}>
					{formattedTotalLiabilities}
				</Typography>
			</Box>
		</>
	);
}

export default UpsidePotentialCoreData;
