import { useState } from 'react';
import {
	Box,
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// Our Components
import CommonPropTypes from 'shared/prop-types';
import { PASSWORD } from 'components/Input/Types';

// Input Types
import {
	isPercentInput,
	isPriceInput,
	isYearInput,
	getInputType
} from './isValidTextInput';

const StyledNumberInput = styled(OutlinedInput)`
	input[type='number'] {
		-moz-appearance: textfield;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
`;

// TODO @Daniel see if we can keep state within an input field and possible toggle debouncing the values when needed as an opt-in
function TextInput({
	defaultValue,
	disabled,
	error,
	helperText,
	inputProps,
	inputSx,
	label,
	labelSx,
	onBlur,
	onChange,
	placeholder,
	required,
	subLabel,
	sx,
	type,
	value,
	variant,
	withVisibleToggle,
	defaultVisibility
}) {
	const [visible, setVisible] = useState(defaultVisibility);

	const [wasChecked, setWasChecked] = useState(false);

	// TODO @Daniel if type is tel then modify the onChange behavior
	return (
		<Box
			sx={{
				opacity: disabled ? 0.5 : 1,
				maxHeight: 100,
				...sx
			}}
		>
			{label && (
				<Typography
					variant={variant}
					sx={{ marginBottom: 1, ...labelSx }}
				>
					{label}
				</Typography>
			)}
			<StyledNumberInput
				// This check is if its visible return the type passed in props
				// Otherwise set the type to password that way the toggle behaves as intended
				sx={inputSx}
				disabled={disabled}
				type={visible ? getInputType(type) : PASSWORD}
				error={wasChecked && error}
				placeholder={placeholder}
				value={value}
				defaultValue={defaultValue}
				onBlur={() => {
					if (wasChecked === false) setWasChecked(true);
					onBlur();
				}}
				onChange={(e) => onChange(e.target.value)}
				onWheel={(e) => e.target.blur()}
				startAdornment={
					isPriceInput(type) && (
						<InputAdornment position="start">$</InputAdornment>
					)
				}
				endAdornment={
					(withVisibleToggle && (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setVisible(!visible)}
								onMouseDown={(e) => e.preventDefault()}
								edge="end"
							>
								{!visible ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)) ||
					(isPercentInput(type) && (
						<InputAdornment position="end">%</InputAdornment>
					)) ||
					(isYearInput(type) && (
						<InputAdornment position="end">Yrs</InputAdornment>
					))
				}
				fullWidth
				required={required}
				inputProps={inputProps}
			/>
			{/* Below may be complex but if checked. There is an error. And we provided helper test display it! */}
			{!subLabel && error && wasChecked && helperText && (
				<FormHelperText
					variant="standard"
					sx={{
						marginLeft: 2
					}}
				>
					{helperText}
				</FormHelperText>
			)}

			{subLabel && (
				<FormHelperText
					variant="standard"
					sx={{
						marginLeft: 2
					}}
				>
					{subLabel}
				</FormHelperText>
			)}
		</Box>
	);
}

TextInput.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	sx: CommonPropTypes.sx,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	labelSx: CommonPropTypes.sx,
	inputSx: CommonPropTypes.sx,
	helperText: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	type: PropTypes.oneOf([
		'email',
		'number',
		'price',
		'percent',
		'tel',
		'text',
		'password',
		'url',
		'visible',
		'year'
	]),
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	variant: PropTypes.string,
	required: PropTypes.bool,
	withVisibleToggle: PropTypes.bool,
	defaultVisibility: PropTypes.bool
};

TextInput.defaultProps = {
	disabled: false,
	error: false,
	sx: {},
	label: undefined,
	inputSx: {},
	labelSx: {},
	helperText: undefined,
	placeholder: undefined,
	onChange: () => {},
	onBlur: () => undefined,
	type: undefined,
	defaultValue: undefined,
	variant: 'subtitle2',
	required: false,
	withVisibleToggle: false,
	defaultVisibility: true
};

export default TextInput;
