import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow
} from '@mui/material';

function MortgageDetailCard({ cardDataRows }) {
	return (
		<TableContainer component={Box}>
			<Table aria-label="Liability Data table">
				<TableBody>
					{/* Check to see which card is being served and conditionally render to correct rows */}
					{cardDataRows.map((row) => (
						<TableRow
							key={row.label}
							sx={{
								'&:last-child td, &:last-child th': {
									border: 0
								}
							}}
						>
							<TableCell
								component="th"
								scope="row"
								sx={{ fontWeight: 'bold' }}
							>
								{row.label}
							</TableCell>
							<TableCell>{row.value}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default MortgageDetailCard;
