import { Box, Link as ExternalLink, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

// Our Components
import AdvisorSupportItem from 'components/Support/AdvisorSupportItem';
import BoxIntegrationiFrame from 'components/Box/BoxIntegrationiFrame';
// Our Routes
import { LOAN_INQUIRY_ROUTE } from 'routes';

// Local Constant
const SUPPORT_ITEMS = [
	{
		title: 'Loans',
		content: (
			<Typography variant="body1">
				To request loan pricing{' '}
				<Link to={LOAN_INQUIRY_ROUTE}>click here</Link>. <br /> For
				inquiries related to an existing loan, send a note to{' '}
				<ExternalLink
					href="mailto:lending@sorafinance.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					lending@sorafinance.com
				</ExternalLink>
				.
			</Typography>
		)
	},
	{
		title: 'General',
		content: (
			<Typography variant="body1">
				For general inquiries, reach out to{' '}
				<ExternalLink
					href="mailto:contact@sorafinance.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					contact@sorafinance.com
				</ExternalLink>
				.
			</Typography>
		)
	},
	{
		title: 'Technical',
		content: (
			<Typography variant="body1">
				For technical or data related issues, reach out to{' '}
				<ExternalLink
					href="mailto:support@sorafinance.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					support@sorafinance.com
				</ExternalLink>
				.
			</Typography>
		)
	},
	{
		title: 'Secure Messaging',
		content: (
			<>
				<Typography
					variant="body1"
					sx={{ display: 'block', marginBottom: 2 }}
				>
					For secured messaging or data sharing, upload documents to
					the below with your name clearly marked in the uploaded
					file.
				</Typography>
				<BoxIntegrationiFrame />
			</>
		)
	}
];

function AdvisorSupportContent() {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
			{SUPPORT_ITEMS.map(({ title, content }) => (
				<AdvisorSupportItem
					key={uuidv4()}
					title={title}
					content={content}
				/>
			))}
		</Box>
	);
}

export default AdvisorSupportContent;
