import { Box, Divider, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';

// Our Components
import CaptionBubble from 'components/Caption/CaptionBubble';
import DynamicBarChart from 'components/Chart/DynamicBarChart';
import ResultsSwitch from 'components/Analytics/Results/ResultsSwitch';
import { SecondaryButton } from 'components/Button/Button';

// Our helpers
const strippedAndLowercased = (c) =>
	c.replace(/\s/g, '').replace(/\d+$/, '').toLowerCase();

// These Results refer to analytics results
function Results({ data, reset }) {
	const SoraTheme = useTheme();

	const { resultsChart } = SoraTheme.palette;

	// false -> present value, true -
	const [isFutureMode, setIsFutureMode] = useState(true);

	const { present, future } = data;

	const currentChartData = isFutureMode ? future : present;

	const { values, labels, caption, recommendation } = currentChartData;

	const currentBarchartData = values.map((value, index) => {
		const currentBar = labels[index];
		const { lender, type } = currentBar;

		const getFillKey =
			type === 'Non-Brokerage'
				? 'nonBrokerage'
				: strippedAndLowercased(type);

		const fill = resultsChart[getFillKey] ?? resultsChart.other;

		return {
			name: type,
			lender,
			value,
			fill
		};
	});

	useEffect(() => {
		const updateDataButton = document.getElementById('scroll-to');
		if (updateDataButton) {
			updateDataButton.scrollIntoView({ behavior: 'smooth' });
		}
	}, [currentBarchartData]);

	const subtitleText = isFutureMode
		? 'Total Impact on Future Net Worth ($K)'
		: 'Total Impact on Present Value of Future Cash Flows ($K)';

	return (
		<>
			<Divider sx={{ marginTop: 4, backgroundColor: 'black' }} />

			<Typography
				id="scroll-to"
				variant="h1Gascogne"
				component="h1"
				sx={{ marginTop: 6 }}
			>
				Your Results
			</Typography>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: 4
				}}
			>
				<Typography
					variant="subtitleGascongne"
					component="h6"
					sx={{ fontSize: 24 }}
				>
					{subtitleText}
				</Typography>

				<CaptionBubble>
					<Typography
						variant="subtitle2"
						component="div"
						sx={{ display: 'flex', alignItems: 'center' }}
					>
						<Typography
							component="span"
							variant="subtitle2"
							fontWeight="bold"
							sx={{ marginRight: 0.5 }}
						>
							Recommendation:
						</Typography>
						<Typography variant="body2" component="span">
							{recommendation}
						</Typography>
					</Typography>
				</CaptionBubble>
			</Box>

			<ResultsSwitch
				checked={isFutureMode}
				setChecked={setIsFutureMode}
			/>

			<Box sx={{ width: '100%', height: 600, marginTop: 4 }}>
				<DynamicBarChart data={currentBarchartData} />
			</Box>

			<Typography
				variant="caption"
				sx={{ display: 'inline-block', marginTop: 4, lineHeight: 2 }}
			>
				{caption}
			</Typography>

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					marginTop: 4
				}}
			>
				<SecondaryButton onClick={() => reset()}>
					Update data
				</SecondaryButton>
			</Box>
		</>
	);
}

export default Results;
