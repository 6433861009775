import { Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Icons
import NumberOne from '@mui/icons-material/LooksOne';
import NumberTwo from '@mui/icons-material/LooksTwo';
import NumberThree from '@mui/icons-material/Looks3';

// Our Components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import BoxIntegrationiFrame from 'components/Box/BoxIntegrationiFrame';

// our Hooks
import useMutateAddClientInvitation from 'hooks/useMutateAddClientInvitation';

// Our Routes
import { DASHBOARD_ROUTE } from 'routes/index';

export function AddClientsBySpreadsheet() {
	const navigate = useNavigate();
	const sentClientInvite = useMutateAddClientInvitation();
	const { isError: inviteClientError } = sentClientInvite;

	return (
		<>
			{inviteClientError && <Alert variant="error" />}

			<Grid container>
				<Grid item xs={12} sx={{ marginBottom: 4 }}>
					<Typography variant="h1Gascogne" component="h1">
						Upload Multiple Clients
					</Typography>
				</Grid>
				<Grid item xs={1} sx={{ marginBottom: 6 }}>
					<NumberOne
						sx={{
							fontSize: 40,
							color: 'primary.lightGrey',
							marginLeft: 4
						}}
					/>
				</Grid>
				<Grid item xs={11} sx={{ marginBottom: 6 }}>
					<Typography>
						Download the spreadsheet and fill it out with your
						client&apos;s details.
					</Typography>
					{/* Download spreadsheet link here */}
					<a
						href="https://prod-public-asset.s3.amazonaws.com/Sora-Automated-Onboarding-Service.xlsx"
						download
						style={{ textDecoration: 'none' }}
					>
						<PrimaryButton sx={{ marginTop: 4 }}>
							Download onboarding spreadsheet
						</PrimaryButton>
					</a>
				</Grid>

				<Grid item xs={1}>
					<NumberTwo
						sx={{
							fontSize: 40,
							color: 'primary.lightGrey',
							marginLeft: 4
						}}
					/>
				</Grid>
				<Grid item xs={11}>
					<Typography>
						Add your client&apos;s details to the spreadsheet.
					</Typography>
				</Grid>

				<Grid item xs={1} sx={{ marginTop: 6 }}>
					<NumberThree
						sx={{
							fontSize: 40,
							color: 'primary.lightGrey',
							marginLeft: 4
						}}
					/>
				</Grid>
				<Grid item xs={11} sx={{ marginTop: 6 }}>
					<Typography sx={{ marginBottom: 6 }}>
						Securely upload your completed spreadsheet and your
						clients will be added to your account within the next 24
						hours.
					</Typography>
					<BoxIntegrationiFrame />
				</Grid>
				<Grid item xs={12} sx={{ marginTop: 6 }}>
					<PrimaryButton
						sx={{ marginTop: 4 }}
						onClick={() => navigate(DASHBOARD_ROUTE)}
					>
						Back to dashboard
					</PrimaryButton>
				</Grid>
			</Grid>
		</>
	);
}

export default AddClientsBySpreadsheet;
