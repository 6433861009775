import { Box, Typography } from '@mui/material';

function CaptionBubble({ caption, children }) {
	return (
		<Box
			id="update-data-button"
			sx={{
				border: '1px solid black',
				padding: 2,
				borderRadius: 3,
				background: 'rgba(38, 131, 194, 0.10)',
				display: 'flex',
				alignItems: 'center'
			}}
		>
			{!children && <Typography variant="caption">{caption}</Typography>}
			{children && children}
		</Box>
	);
}

export default CaptionBubble;
