import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import CardItem from 'components/SoraWallet/Cards/CardItem';
import UpsidePotentialCoreData from 'components/SoraWallet/Cards/UpsidePotential/UpsidePotentialCoreData';

// Our Assets
import WalletGraph from 'assets/icons/wallet_icons/WalletGraph.svg';

function UpsidePotentialUntappedSavingsCard({
	upsidePotential,
	untappedSavings,
	totalLoans,
	totalLiabilities,
	variant
}) {
	const cardImage = WalletGraph;

	return (
		<CardItem
			withoutButton
			buttonSx={{ alignSelf: 'center' }}
			sx={{
				background: 'linear-gradient(180deg, #295983 0%, #91B0CB 100%)',
				gap: '20px',
				padding: '20px 28px 16px 28px'
			}}
		>
			<UpsidePotentialCoreData
				upsidePotential={upsidePotential}
				untappedSavings={untappedSavings}
				totalLoans={totalLoans}
				totalLiabilities={totalLiabilities}
				variant={variant}
			/>
			<Box
				component="img"
				src={cardImage}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'absolute',
					right: '1rem',
					bottom: 0,
					width: '4.6rem !important',
					height: '6.6rem !important',
					marginBottom: '0.5rem !important'
				}}
			/>
		</CardItem>
	);
}

UpsidePotentialUntappedSavingsCard.propTypes = {
	variant: PropTypes.oneOf(['old', 'new'])
};

UpsidePotentialUntappedSavingsCard.defaultProps = {
	// TODO: Keeping this for now since we may revert back to old card
	variant: 'new'
};

export default UpsidePotentialUntappedSavingsCard;
