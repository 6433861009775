import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

function AdvisorSupportItem({ title, content }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	return (
		<Box>
			<Typography
				variant="h2Gascogne"
				sx={{
					display: 'block',
					color: primary.indigo,
					marginBottom: 1
				}}
			>
				{title}
			</Typography>
			{content}
		</Box>
	);
}

export default AdvisorSupportItem;
