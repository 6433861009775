import { Grid } from '@mui/material';

// Our Components
import PageHeading from 'components/PageHeading/PageHeading';
import AdvisorSupportContent from 'components/Support/AdvisorSupportContent';

function SupportPage() {
	return (
		<>
			<PageHeading title="Support" />

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<AdvisorSupportContent />
				</Grid>
			</Grid>
		</>
	);
}

export default SupportPage;
