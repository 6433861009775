import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import BalanceAndMonthyAmountCard from 'components/SoraWallet/Cards/BalanceAndMonthyAmountCard';
import DetailedListCard from 'components/SoraWallet/Cards/DetailedListCard';
import EquityAvailableCard from 'components/SoraWallet/Cards/EquityAvailableCard';
import InterestAndYearsRemainingCard from 'components/SoraWallet/Cards/InterestAndYearsRemainingCard';
import LoanTypeAndCostOfDebtCard from 'components/SoraWallet/Cards/LoanTypeAndCostOfDebtCard';
import UpsidePotentialCard from 'components/SoraWallet/Cards/UpsidePotentialCard';
import UntappedSavingsCard from 'components/SoraWallet/Cards/UntappedSavingsCard';
import SoraInsightsMortgageCarousel from 'components/Carousel/SoraInsightsMortgageCarousel';

// Tool Tips
import WalletToolTips from 'components/SoraWallet/WalletToolTips.json';

const DEFAULT_ROW_SPACING = 6;

function HomeWallet({
	currentLoanDetails,
	contactClientData,
	firstName,
	formattedSoraGrade,
	isClient,
	lastName,
	upsidePotential,
	untappedSavings,
	walletLiabilityData
}) {
	const SoraTheme = useTheme();

	const { currentLoan } = walletLiabilityData;

	const { home: homeDetails, lender } = currentLoanDetails;

	return (
		<Grid
			container
			item
			xs={12}
			columnSpacing={3}
			rowSpacing={DEFAULT_ROW_SPACING}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-evenly'
			}}
		>
			<Grid item xs={12} md={4} xl={4}>
				<LoanTypeAndCostOfDebtCard
					currentLoanDetails={currentLoanDetails}
					homeDetails={homeDetails}
					firstName={firstName}
					lastName={lastName}
					lender={lender}
					soraGrade={formattedSoraGrade}
				/>
			</Grid>
			<Grid item xs={12} md={4} xl={4}>
				<BalanceAndMonthyAmountCard
					currentLoanDetails={currentLoanDetails}
				/>
			</Grid>
			<Grid item xs={12} md={4} xl={4}>
				<InterestAndYearsRemainingCard
					remainingMonth={currentLoanDetails?.remainingMonth}
					currentLoanDetails={currentLoanDetails}
				/>
			</Grid>
			{/* 
			<Grid container item xs={12} columnSpacing={3}>
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						sx={{
							color: SoraTheme.palette.primary.indigo,
							display: 'block',
							marginBottom: 3
						}}
					>
						Liability Insights
					</Typography>
				</Grid>
				<Grid item xs={12} md={4} xl={4} sx={{ height: 195 }}>
					<UpsidePotentialCard
						heading="Upside Potential"
						calculatedValue={upsidePotential}
						tooltTipText={WalletToolTips.upsidePotential}
					/>
				</Grid>
				<Grid item xs={12} md={4} xl={4} sx={{ height: 195 }}>
					<UntappedSavingsCard
						heading="Untapped Savings"
						calculatedValue={untappedSavings}
						tooltTipText={WalletToolTips.untappedSavings}
					/>
				</Grid>
				<Grid item xs={12} md={4} xl={4} sx={{ height: 195 }}>
					<EquityAvailableCard
						isClient={isClient}
						currentLoanDetails={currentLoanDetails}
						clientData={contactClientData}
					/>
				</Grid>
			</Grid> */}

			<SoraInsightsMortgageCarousel isClient={isClient} />

			<Grid item sm={12}>
				<DetailedListCard
					variant="property"
					cardHeading="Property Data"
					currentLoan={currentLoan}
				/>
			</Grid>

			<Grid item sm={12} sx={{ marginBottom: 5 }}>
				<DetailedListCard
					variant="liability"
					cardHeading="Current Liabilities Data"
					currentLoan={currentLoan}
				/>
			</Grid>
		</Grid>
	);
}

export default HomeWallet;
