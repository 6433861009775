import { Box, Grid, Tabs, Tab, Tooltip, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// Our Components
import TabPanel from 'components/Tabs/TabPanel';

// Our Prop Types
import CommonPropTypes from 'shared/prop-types';

function DynamicTabs({
	dataTestTag,
	handleChange,
	sx,
	tabItems,
	tabPanelItems,
	tabsSX,
	tabItemSX,
	toolTipText,
	value,
	variant,
	children,
	tabGridItemProps
}) {
	return (
		<Box sx={{ ...sx }}>
			<Grid container sx={{ justifyContent: 'space-between' }}>
				<Grid item xs={12} {...tabGridItemProps}>
					<Tabs
						variant={variant}
						scrollButtons="auto"
						data-test={dataTestTag}
						value={value}
						onChange={handleChange}
						aria-label="tabular navigation"
						sx={tabsSX}
						TabIndicatorProps={{
							sx: { height: 4 }
						}}
					>
						{tabItems.map(({ type, disabled }, i) => {
							const isLastTab = i === tabItems.length - 1;
							if (disabled) {
								return (
									<Tooltip key={uuidv4()} title={toolTipText}>
										<Box>
											<Tab
												disabled={disabled ?? false}
												disableRipple
												label={
													<Typography variant="subtitle1">
														{type}
													</Typography>
												}
												sx={{
													...tabItemSX,
													...(isLastTab && {
														marginRight: 0
													})
												}}
											/>
										</Box>
									</Tooltip>
								);
							}

							const isCurrentTabSelected = value === i;
							return (
								<Tab
									disableRipple
									label={
										<Typography
											variant="subtitle1"
											sx={{
												color: 'black',
												opacity: isCurrentTabSelected
													? 1
													: 0.5
											}}
										>
											{type}
										</Typography>
									}
									key={uuidv4()}
									sx={{
										...tabItemSX,
										...(isLastTab && { marginRight: 0 })
									}}
								/>
							);
						})}
					</Tabs>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					lg={4}
					sx={{ marginBottom: { xs: 2, lg: 0 } }}
				>
					{children}
				</Grid>
			</Grid>
			{tabPanelItems &&
				tabPanelItems.map(
					({ panelItemText, children: tabPanelChildren }, i) => (
						<TabPanel value={value} index={i} key={uuidv4()}>
							{panelItemText && (
								<Typography
									variant="body2"
									sx={{ height: 20, marginLeft: 2 }}
								>
									{panelItemText}
								</Typography>
							)}
							{tabPanelChildren}
						</TabPanel>
					)
				)}
		</Box>
	);
}

// Mui Docs - https://mui.com/material-ui/api/tabs/
DynamicTabs.propTypes = {
	handleChange: PropTypes.func,
	sx: CommonPropTypes.sx,
	tabsSX: CommonPropTypes.sx,
	tabItemSX: CommonPropTypes.sx,
	toolTipText: PropTypes.string,
	value: PropTypes.number.isRequired,
	variant: PropTypes.oneOf(['standard', 'scrollable', 'fullWidth']),
	tabGridItemProps: CommonPropTypes.sx
};

DynamicTabs.defaultProps = {
	handleChange: () => null,
	sx: {},
	tabsSX: {},
	tabItemSX: {},
	toolTipText: '',
	variant: 'standard',
	tabGridItemProps: {}
};

export default DynamicTabs;
