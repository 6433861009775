import { useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import AvatarUpload from 'components/FileUpload/AvatarUpload';
import Badge from 'components/Avatar/Badge';
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import ProfilePicturePlaceHolder from 'components/Avatar/ProfilePicturePlaceHolder';

// our Hooks
import useGetProfilePictureOrCompanyLogo from 'hooks/advisorProfile/useGetProfilePictureOrCompanyLogo';
import { PrimaryButton } from 'components/Button/Button';

const DISPLAY_DIMENSIONS = { height: 120, width: 120 };

// Description
const DESCRIPTION = 'Upload a .png or .jpg image. Max file size: 5MB.';

// Main Component
function ProfileAvatar({ isCorporate }) {
	// Constants
	const modalTitle = isCorporate
		? 'Update your company logo here'
		: 'Update your profile photo here';

	const labelHeading = isCorporate ? 'Company Logo' : 'Profile Photo';

	// Local state
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { isLoading, isSuccess, data } =
		useGetProfilePictureOrCompanyLogo(isCorporate);

	const closeModal = useCallback(() => setIsModalOpen(false), []);

	if (isLoading) return <Loader size={60} />;

	if (isSuccess) {
		const { imageUrl, timestamp } = data;

		const displayFallBack = !imageUrl;

		const labelMarginRightStyling = isCorporate && displayFallBack ? 3 : 0;

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
					width: 'fit-content'
				}}
			>
				<Typography
					variant="subtitle1"
					sx={{ marginRight: labelMarginRightStyling }}
				>
					{labelHeading}
				</Typography>
				<Typography
					variant="body2"
					sx={{ display: 'block', marginBottom: 2 }}
				>
					{DESCRIPTION}
				</Typography>

				<Badge handleClick={() => setIsModalOpen(true)}>
					{!displayFallBack && (
						<Box
							key={timestamp}
							component="img"
							src={imageUrl}
							sx={{
								borderRadius: '50%',
								objectFit: 'contain',
								...DISPLAY_DIMENSIONS
							}}
						/>
					)}

					{displayFallBack && (
						<ProfilePicturePlaceHolder isCorporate={isCorporate} />
					)}
				</Badge>

				<Modal
					title={modalTitle}
					isOpen={isModalOpen}
					handleClose={closeModal}
				>
					<Grid container>
						<Grid item xs={12}>
							<AvatarUpload
								appear
								isCorporate={isCorporate}
								withoutAnimation
							/>
						</Grid>
						<Grid item xs={12}>
							<PrimaryButton
								sx={{ marginTop: 4 }}
								onClick={closeModal}
							>
								Close
							</PrimaryButton>
						</Grid>
					</Grid>
				</Modal>
			</Box>
		);
	}
}

ProfileAvatar.propTypes = {
	isCorporate: PropTypes.bool
};

ProfileAvatar.defaultProps = {
	isCorporate: false
};

export default ProfileAvatar;
