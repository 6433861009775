import { Box, Divider, Typography } from '@mui/material';
import { useEffect } from 'react';

// Our Components
import CaptionBubble from 'components/Caption/CaptionBubble';
import DynamicBarChart from 'components/Chart/DynamicBarChart';
import ResultsSwitch from 'components/Analytics/Results/ResultsSwitch';
import { TertiaryButton } from 'components/Button/Button';
import Tooltip from 'components/Tooltip';

// Our Utils
import { dollarFormatter } from 'shared/utils';

// Format Caption
const formatCaption = (caption) => {
	const regex = /\$-?[0-9]+(?:\.[0-9]{1,2})?/g;

	const formattedCaption = caption.replace(regex, (match) => {
		const strippedMatch = match.replace('$', '');
		const formattedMatch = dollarFormatter.format(strippedMatch);

		return formattedMatch;
	});
	return formattedCaption;
};

function ResultChart({
	subtitleText,
	recommendation,
	isFutureMode,
	setIsFutureMode,
	isSwitchable,
	currentBarchartData,
	caption,
	reset,
	withTooltip,
	tooltipText,
	children
}) {
	useEffect(() => {
		// scroll to 	id="update-data-button"
		const updateDataButton = document.getElementById('update-data-button');
		if (updateDataButton) {
			updateDataButton.scrollIntoView({ behavior: 'smooth' });
		}
	}, [currentBarchartData]);

	const formattedCaption = formatCaption(caption);
	return (
		<>
			<Divider sx={{ marginTop: 4, backgroundColor: 'black' }} />

			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginTop: 6
				}}
			>
				<Typography variant="h1Gascogne" component="h1">
					Your Results
				</Typography>

				<CaptionBubble>
					<Typography
						variant="subtitle2"
						component="div"
						sx={{ display: 'flex', alignItems: 'center' }}
					>
						<Typography
							component="span"
							variant="subtitle2"
							fontWeight="bold"
							sx={{ marginRight: 0.5 }}
						>
							Recommendation:
						</Typography>{' '}
						<Typography variant="body2">
							{recommendation}
						</Typography>
					</Typography>
				</CaptionBubble>
			</Box>

			<Box
				sx={{
					width: '100%',
					marginTop: 4,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Typography
					variant="subtitleGascongne"
					component="h6"
					sx={{ fontSize: 24 }}
				>
					{subtitleText}
					{withTooltip && (
						<Tooltip
							text={tooltipText}
							toolTipSx={{
								fontSize: 16,
								marginLeft: 0.5,
								marginBottom: 1.5
							}}
						/>
					)}
				</Typography>
			</Box>

			{isSwitchable && (
				<ResultsSwitch
					checked={isFutureMode}
					setChecked={setIsFutureMode}
				/>
			)}

			<Box sx={{ width: '100%', height: 300, marginTop: 4 }}>
				<DynamicBarChart data={currentBarchartData} />
			</Box>

			<Typography
				variant="caption"
				sx={{
					display: 'inline-block',
					marginTop: 4
				}}
			>
				{formattedCaption}
			</Typography>

			{children && children}
			{!children && (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						marginTop: 4
					}}
				>
					<TertiaryButton onClick={() => reset()}>
						Update data
					</TertiaryButton>
				</Box>
			)}
		</>
	);
}

export default ResultChart;
