import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';
import { Grid } from '@mui/material';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';
import DeleteButton from 'components/DataTable/DeleteButton';
import InviteButton from 'components/DataTable/InviteButton';

// Utilities
import { dollarFormatter } from 'shared/utils';

function LiabilitiesTable({ liabilities }) {
	// the icons are based off what type of loan it is. home mortgage
	// car is a car loan
	// cap is student loan
	// money is money loan

	// currently in our liabilities table there isn't an id associated with the user so i'm created one here per row

	const liabilityData = useMemo(
		() =>
			liabilities.map((userLiabilityData) => ({
				...userLiabilityData,
				deleteKey: '',
				inviteKey: '',
				id: uuidv4()
			})),
		[liabilities]
	);

	const nameKey = 'name';
	const balanceKey = 'outstandingBalance';
	const numberOfLoansKey = 'numberOfLoans';
	const deleteKey = 'deleteKey';
	const inviteKey = 'inviteKey';
	const creditScoreKey = 'creditScore';

	const columnsLiability = [
		ColumnItem(nameKey, 'Name', 1.1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(numberOfLoansKey, 'Loans', 0.6, {
			valueFormatter: (params) => {
				const numberOfLoans = params.value;

				if (numberOfLoans || numberOfLoans === 0) return numberOfLoans;

				return 'NA';
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),

		ColumnItem(balanceKey, 'Outstanding Balance', 1, {
			valueFormatter: (params) => {
				const outstandingBalance = params.value;
				if (!outstandingBalance && outstandingBalance !== 0)
					return 'NA';
				if (outstandingBalance === 0) return '$0';
				return dollarFormatter.format(outstandingBalance);
			},
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(creditScoreKey, 'Credit Score', 1, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			)
		}),
		ColumnItem(inviteKey, 'Login Link', 0.7, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),
			renderCell: (params) => {
				const rowData = params.row;
				return <InviteButton rowData={rowData} />;
			}
		}),
		ColumnItem(deleteKey, 'Delete', 0.5, {
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),
			renderCell: (params) => {
				const rowData = params.row;
				return <DeleteButton rowData={rowData} />;
			}
		})
	];

	return (
		<Grid item xs={12}>
			<DataTable
				columns={columnsLiability}
				rows={liabilityData}
				rowsPerPageOptions={10}
				withRowColorCheck
			/>
		</Grid>
	);
}

export default LiabilitiesTable;
