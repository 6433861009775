import { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Zoom } from '@mui/material';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

// Our Components
import Alert from 'components/Alert';
import DragFileMessage from 'components/FileUpload/DragFileMessage';
import FileUpload from 'components/FileUpload/FileUpload';
import ImageCropper from 'components/FileUpload/ImageCropper/ImageCropper';
import ProfilePicturePlaceHolder from 'components/Avatar/ProfilePicturePlaceHolder';
import { SecondaryButton } from 'components/Button/Button';

// Our Query Keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Local Constants
const MAX_FILE_SIZE_5_MB = 5e6;
const acceptedMimeTypes = {
	accept: {
		'image/*': []
	}
};

function AvatarUpload({ appear, isCorporate, withoutAnimation }) {
	const queryClient = useQueryClient();
	const [isBackdropOpen, setIsBackdropOpen] = useState(false);
	const [image, setImage] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [files, setFiles] = useState([]);
	const [causeRefresh, setCauseRefresh] = useState('');

	const [filesRejected, setFilesRejected] = useState([]);

	const updateAcceptedFiles = useCallback((data) => {
		const incomingDataIsEmpty = data.length < 1;
		if (files.length > 1 || incomingDataIsEmpty) return;
		setFiles(data);
		setImage(URL.createObjectURL(data[0].fileData));
		setIsBackdropOpen(true);
	}, []);

	const updateRejectedFiles = useCallback((data) => {
		queryClient.setQueryData(
			ERROR_MESSAGE_DATA,
			'Image must be less than 5MB'
		);
		setCauseRefresh(uuidv4());
		setFilesRejected(data);
	}, []);

	const handleBackdropClose = () => {
		if (!croppedImage) {
			setFiles([]);
			setCroppedImage(null);
			setImage(null);
		}
		setIsBackdropOpen(false);
	};

	const borderRadiusBasedOnIsCorporate = isCorporate ? 0 : 150;

	const dropZoneHeader = isCorporate
		? 'Your company logo'
		: 'Your profile photo';

	const topPaddingBasedOnCrop = croppedImage !== null ? 2 : 0;
	const transitionDelay = withoutAnimation ? '0ms' : '100ms';

	const isUploadError = queryClient.getQueryData(ERROR_MESSAGE_DATA);

	useEffect(() => () => queryClient.removeQueries(ERROR_MESSAGE_DATA), []);

	return (
		<Zoom in={!isCorporate ? true : appear} style={{ transitionDelay }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					marginTop: 4
				}}
			>
				{isUploadError && <Alert key={causeRefresh} variant="error" />}
				<FileUpload
					acceptedFileTypes={acceptedMimeTypes}
					files={files}
					filesRejected={filesRejected}
					maxFileSize={MAX_FILE_SIZE_5_MB}
					handleAcceptedFileCallBack={updateAcceptedFiles}
					handleRejectFileCallBack={updateRejectedFiles}
					sx={{
						marginTop: 0,
						padding: 0,
						paddingTop: topPaddingBasedOnCrop,
						paddingBottom: 2,
						minHeight: 360,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between'
					}}
					OnDragComponent={DragFileMessage}
				>
					{!croppedImage && (
						<>
							<ProfilePicturePlaceHolder
								withDraggable
								isCorporate={isCorporate}
							/>
							<Typography variant="body1">- or -</Typography>
						</>
					)}

					{croppedImage && (
						<>
							<Typography variant="subtitle1">
								{dropZoneHeader}
							</Typography>
							<Box
								component="img"
								src={croppedImage}
								sx={{
									width: 200,
									height: 'auto',
									borderRadius: borderRadiusBasedOnIsCorporate
								}}
							/>

							<SecondaryButton
								onClick={() => {
									if (image && image.src) {
										URL.revokeObjectURL(image.src);
									}
									setFiles([]);
									setCroppedImage(null);
									setImage(null);
								}}
							>
								Change
							</SecondaryButton>
						</>
					)}
				</FileUpload>

				<ImageCropper
					isBackdropOpen={isBackdropOpen}
					setIsBackdropOpen={setIsBackdropOpen}
					files={files}
					gracefullyCloseBackdrop={handleBackdropClose}
					imageSrc={image}
					isCorporate={isCorporate}
					setCroppedImage={setCroppedImage}
				/>
			</Box>
		</Zoom>
	);
}

AvatarUpload.propTypes = {
	isCorporate: PropTypes.bool,
	withoutAnimation: PropTypes.bool
};

AvatarUpload.defaultProps = {
	isCorporate: false,
	withoutAnimation: false
};

export default AvatarUpload;
