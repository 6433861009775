import { Badge as MuiBadge, Box } from '@mui/material';
import { useTheme } from '@emotion/react';

// Icons
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

function CameraWithBadge({ handleClick, badgeSx, primary }) {
	return (
		<Box
			sx={{
				bgcolor: 'black',
				borderRadius: '50%',
				height: 50,
				width: 50,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				...badgeSx
			}}
		>
			<PhotoCameraIcon
				sx={{
					width: 30,
					height: 30,
					color: primary.white,
					borderRadius: '50%',
					padding: '2px',
					'&:hover': {
						opacity: 0.6,
						cursor: 'pointer'
					}
				}}
				onClick={handleClick}
			/>
		</Box>
	);
}

function Badge({ children, badgeSx, handleClick }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	return (
		<Box>
			<MuiBadge
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				overlap="circular"
				badgeContent={
					<CameraWithBadge
						handleClick={handleClick}
						badgeSx={badgeSx}
						primary={primary}
					/>
				}
			>
				{children}
			</MuiBadge>
		</Box>
	);
}

export default Badge;
