import { Box, Link as ExternalLink, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

// Our Components
import SoraToolTip from 'components/Tooltip';

// helper fn
const getLiabilityConfidenceColor = (confidenceLevel) => {
	const confidenceToColorMapping = {
		Low: 'red',
		Medium: '#bfa70a',
		High: 'green',
		manual: 'green',
		Manual: 'green'
	};

	return confidenceToColorMapping[confidenceLevel] ?? 'red';
};

const HIGH_CONFIDENCE_VALUES = new Set(['High', 'manual', 'Manual']);

const confidenceToolTips = {
	High: 'Data sourced from lender directly and credit report.',
	Medium: 'Data sourced from credit report and Sora enrichment calculations.',
	Low: 'Data sourced from credit report',
	manual: 'Data sourced from lender directly and credit report.',
	Manual: 'Data sourced from lender directly and credit report.'
};

function DataConfidence({ value }) {
	const isHighConfidence = HIGH_CONFIDENCE_VALUES.has(value);

	const tooltipText = confidenceToolTips[value];

	if (isHighConfidence) {
		return (
			<SoraToolTip text={tooltipText} placement="top">
				<Typography variant="body2">{value}</Typography>
			</SoraToolTip>
		);
	}

	const infoColor = getLiabilityConfidenceColor(value);

	return (
		<SoraToolTip
			placement="top"
			text={
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 0.5,
						textAlign: 'center'
					}}
				>
					<Typography variant="body2">{tooltipText}</Typography>
					<Typography variant="body2">
						More details{' '}
						<ExternalLink
							variant="body2"
							href="https://www.sorafinance.com/data-confidence"
							target="_blank"
							rel="noopener noreferrer"
							sx={{
								display: 'inline-block',
								color: 'black',
								textDecoration: 'underline',
								'&:visited': {
									color: 'initial'
								},
								fontWeight: 'bold'
							}}
						>
							here
						</ExternalLink>
						.
					</Typography>
				</Box>
			}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
				<InfoIcon
					sx={{
						fontSize: '1rem',
						color: infoColor
					}}
				/>
				<Typography variant="body2">{value}</Typography>
			</Box>
		</SoraToolTip>
	);
}

export default DataConfidence;
